.nav{
}
.tabs {
  overflow: auto;

  .color-white {
    color: white;
  }

  .text-right {
    text-align: right;
  }

  .al-center {
    text-align: center;
  }

  .btn-remove-blacklist {
    color: #B2456C;
    border: none;
    background: none;
  }

  .btn-details-blacklist {
    color: #333;
    border: none;
    background: none;
  }

  .table-filename {
    max-width: 200px !important;
  }


  .tabs__wrap {
    min-width: min-content;
    //-webkit-min-width: min-content;
  }

  .nav-tabs {
    flex-wrap: nowrap;

    li {

      .nav-link {
        padding: 15px 20px;
        transition: background-color 0.3s;
        cursor: pointer;
        color: #778DE5;

        &:hover, &:focus, &:active {
          outline: none;
          border: none;
        }
      }

      .nav-link.active {
        background-color: transparent;
        border-color: white;
      }

      .nav-link.active:focus,
      .nav-link.active:hover {
        background-color: #ebebeb;
        border-color: white;
        border-bottom: 2px solid transparent;
      }

      &.disabled .nav-link {
        color: $color-additional;
      }
    }
  }

  .tab-pane {
    padding: 40px;

    @media all and (max-width: 990px) {
      padding: 40px 20px;
    }
  }

  &.tabs--bordered-top {

    .nav-tabs {
      li .nav-link {
        border-top: 2px solid transparent;
        border-radius: 0;
      }

      li .nav-link.active {

        &, &:focus, &:hover {
          border-radius: 0;
          border-top: 2px solid $color-accent;
        }
      }
    }
  }

  &.tabs--bordered-bottom {

    .nav-tabs {

      li .nav-link {
        border-color: transparent;
        border-bottom: 1px solid transparent;
        padding-bottom: 11px;

        &:hover, &:focus, &:active {
          border-bottom: 2px solid #484848 !important;
        }
      }

      li .nav-link.active {
        border-bottom: 2px solid #388FDF;

        &:focus, &:hover {
          border-radius: 0;
          border-color: transparent;
          padding-bottom: 10px;
        }
      }
    }
  }

  &.tabs--justify {

    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;

      li {
        width: 100%;

        a {
          text-align: center;
        }
      }
    }
  }

  &.tabs--vertical {
    overflow-y: hidden;

    .tabs__wrap {
      display: flex;
    }

    .nav-tabs {
      border-bottom: none;
      height: 100%;
      flex-wrap: wrap;
      width: 500px;

      @media all and (max-width: 980px) {
        width: auto;
      }

      li {
        margin-top: 0;
        width: 100%;

        .nav-link {
          padding-right: 20px;
          padding-left: 5px;
          margin-right: 0;
          border-right: 2px solid #dddddd;
          transition: background-color 0.3s;
          border-radius: 0;
          color: #778DE5;
          font-weight: 600;

          @media all and (max-width: 980px) {
            padding-right: 10px;
            padding-left: 0;
          }

          &:hover, &:focus, &:active {
            outline: none;
            background-color: white;
            font-weight: 700;
            color: #646777;
          }
          &:hover {
            border-right-color: #839af8;
          }
        }

        .nav-link.active, .nav-link.active:focus,
        .nav-link.active:hover {
          background-color: transparent;
          border-right-color: #778DE5;
          font-weight: 700;
          color: #000000;
        }

        &.disabled .nav-link {
          color: $color-additional;
        }
      }
    }

    .tab-pane {
      padding-top: 0;
      padding-left: 20px;

      &.x-padding {
        padding: 10px 10px 10px 40px;
      }
    }

    &.tabs--vertical-colored {

      .nav-tabs {
        li {

          .nav-link {
              border-right-color: #eeeeee;
              padding-left: 10px;
              text-align: center;
          }
          .nav-link.active, .nav-link.active:focus,
          .nav-link.active:hover {
            color: #646777;
          }
        }
      }
    }
  }

  .tabs--background {
    background: #d2ecff59;
  }

  span.new {
    background: #D1FFD0;
    font-size: 10px;
    padding: 0 6px;
    color: $color-new-gray;
    border-radius: 15px;
    display: block;
    float: right;
    margin-right: 30px;
    width: 35px;
    margin-bottom: -20px;
  }
}
