.crm-container {

  .row {
    display: block;
    overflow-x: auto;
    white-space: nowrap;

    @media all and (max-width: 992px){
      padding-right: 60px;
    }

    @media all and (max-width: 768px){
      padding-right: 40px;
    }

    @media all and (max-width: 576px){
      padding-right: 20px;
    }

    .col-crm {
      display: inline-grid;
      width: 321px;
      padding: 15px 0;

      .col-title {
        height: 50px;
        background-color: white;
        width: 100%;
        border-bottom: 1px solid #f2f4f7;
        position: relative;

        h6 {
          line-height: 50px;
          width: 100%;
          text-align: center;
          font-weight: 400;
          font-size: 18px;
          color: $color-bg-primary;
          text-transform: uppercase;
        }
      }
      .col-title:not(.visited-title) {
        &.col-title:before, &.col-title:after {
          content: ' ';
          display: block;
          width: 0;
          height: 0;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          top: 12px;
          right: -10px;
          z-index: 1;
          position: absolute;
        }

        &.col-title:before {
          border-left: 10px solid #e6e8ec;
          right: -11px;
        }
      }

      .inner-column {
        padding: 15px;
        height: calc(100vh - 250px);
        min-height: 300px;
        overflow-y: scroll;
        background-color: rgba(112, 129, 154, 0.15);

        &.opened-col {
          background-color: rgba(112, 129, 154, 0.25);
        }

        &.replied-col {
          background-color: rgba(112, 129, 154, 0.25);
        }

        &.visited-col {
          background-color: rgba(112, 129, 154, 0.25);
        }
      }
      .card-crm {
        padding: 20px;
        cursor: pointer;
        border-radius: 10px;

        &.no-email {
          opacity: 0.6;
        }

        img.card-crm-pic {
          width: 45px;
          height: auto;
          border-radius: 25px;
          float: left;
          margin-right: 15px;
        }

        .opened, .visited, .replied {
          background-color: $color-new-gray;
          position: absolute;
          left: 0;
          bottom: 30px;
          width: 100%;
          border-radius: 0 0 10px 10px;
          color: white;
          text-align: center;
          height: 30px;
        }
        .card-mini-actions {
          display: flex;
          flex-direction: column;
          width: min-content;
          align-items: center;
          position: absolute;
          right: 10px;
          top: 15px;
        }
        .extinvestor__report {
          cursor: pointer;
          position: absolute;
          right: 20px;
          bottom: 83px;
          color: #B2456C;
        }
        .chevron-down {
          width: 35px;
          height: 35px;
          color: #5a6268;
          transition: transform 150ms ease;
        }
        .opened-and-visited {
          position: absolute;
          left: 0;
          bottom: 30px;
          width: 100%;
          color: white;
          text-align: center;
          height: 30px;

          .opened-half, .visited-half {
            width: 50%;
            display: inline-block;
          }

          .opened-half {
            background-color: $color-new-gray;
            border-radius: 0 0 0 10px;
            height: 30px;
          }

          .visited-half {
            background-color: $color-new-gray;
            border-radius: 0 0 10px 0;
            height: 30px;
          }
        }
        .replied {
          position: absolute;
          left: 0;
          bottom: 30px;
          width: 100%;
          color: white;
          text-align: center;
          height: 30px;

          .opened-third, .visited-third, .replied-third {
            width: 33%;
            display: inline-block;
          }

          .opened-third {
            background-color: $color-new-gray;
            border-radius: 0 0 0 10px;
            height: 30px;
          }

          .visited-third {
            background-color: $color-new-gray;
            border-radius: 0 0 0 0;
            height: 30px;
          }

          .replied-third {
            background-color: $color-new-gray;
            border-radius: 0 0 10px 0;
            height: 30px;
          }
        }

        .opened-img {
          width: 22px;
          margin: 4px 3px 0 0;
        }
        .visited-img {
          width: 31px;
          margin: 3px 2px 0 0;
        }
        .replied-img {
          width: 25px;
          margin: 4px 0 0 0;
        }

        .paused-icon {
          position: absolute;
          left: 46px;
          top: 40px;
          width: 30px;
          height: 30px;
          border-radius: 15px;
          border: 1px solid #C1C1C1;
          text-align: center;
          line-height: 29px;
          background-color: white;

          svg {
            width: 20px;
          }
        }

        // EXTRA INFO
        .hide {
          opacity: 0;
          height: 0;
          padding: 0;
          margin: 0;
        }
        .card-extra {
          display: block;
          bottom: 15px;
          height: 0;
          transition: all 0.4s ease-out;

          .info-table {
            margin-top: 10px;

            svg {
              width: 20px;
              height: 20px;
            }
            .td-campaign {
              overflow: hidden;
              vertical-align: middle;
              color: $color-blue!important;
              font-size: 13px!important;
              text-align: left!important;
            }
          }

          .extra-info {
            display: block;
            overflow: auto;
            min-width: 245px;
            width: 115%;
            margin-left: -20px;
            transition: all 0.4s ease-out;
            padding: 0 20px;

            .info-table {
              margin-bottom: 0;

              tr td {
                border: 0;

                &:last-child {
                  text-align: right;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 20px;
                  color: black;
                }

                &:first-child {
                  color: $color-additional;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 20px;
                }
              }
            }
          }

          .card-actions {
            transition: all 0.4s ease-out;
            text-align: center;

            .btn-pause {
              transition: all 0.4s ease-out;
              display: inline-block;
              margin-right: 10px;

              svg {
                margin: 0 7px 0 0;
                fill: #67BAEB;
              }
            }

            .btn-review {
              transition: all 0.4s ease-out;
              display: inline-block;
              margin-right: 10px;

              svg {
                margin-top: -2px;
                fill: #67BAEB;
              }
            }

            .mail-action {
              margin-left: 37px;
            }
            .info-icon {
              float: right;
            }
          }
        }

        &.extra-margin {
          margin-bottom: 22px;
        }
      }

      .card-crm:hover {
        background-color: #f9fcfd;
        box-shadow: 0 0 14px 0 rgb(230, 233, 246);
      }

      //EXTEND STYLING
      .card-crm.extend > .hide {
        height: auto!important;
        opacity: 1;
      }
      .card-crm.extend .card-extra {
        margin-top: 20px;
      }
      .card-crm.extend .extra-info {
        opacity: 100;
        padding: 0 20px 15px;
        height: auto;
      }
      .card-crm.extend .card-actions {
        opacity: 100;
        padding: 0px 10px;
        display: block;
        height: 41px;
      }
      .card-crm.extend .chevron-down {
        transform: rotate( -180deg );
        transition: transform 150ms ease;
      }
    }

    // STATS
    .crm-stats-container {
      width: 100%;
      background: white;
      height: 190px;
      display: flex;
      margin-bottom: 20px;
      position: relative;
      padding: 10px;


      .crm-stats {
        position: relative;
        margin: 0;
        padding: 20px;
        text-align: center;
        font-size: 12px;
        border-radius: -4px;
        transition: all .234s;
        border: 1px solid #ebeeff;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 14px 0 rgb(230, 233, 246);
        background-color: rgba(112,129,154, 0.15);

        &.opened-col, &.visited-col, &.replied-col {
          background-color: $color-new-gray;

          h3 {
            color: white;

            small {
              color: #9aa0a7;
            }
          }
        }

        &.stat-opened {
          background-color: #d0edf3;
          color: #7ae0f3!important;
        }

        img {
          height: 42px;
          max-width: 60px;
          margin: auto;

          &.followup {
            width: 55px;
          }
        }

        h3 {
          font-size: 18px;
          border-radius: 5px;
          margin: 10px auto;
          min-width: 150px;
          color: $color-bg-primary;
          font-weight: 500;
          text-transform: uppercase;

          small {
            font-size: 16px;
            color: #646777;
            font-weight: 300;
          }
        }
        h4 {
          background-color: white;
          font-weight: 400;
          width: 75px;
          height: 30px;
          line-height: 30px;
          font-size: 16px;
          border-radius: 20px;
          margin: auto;
          &.contacted {
            background-color: #D1FFD0;
          }
          &.followup {
            background-color: #D8D0FF;
          }
        }
      }
    }

    .col-crm:first-child {
      .inner-column, .col-title {
        border-radius: 15px 0 0 15px;
      }
    }

    .col-crm:last-of-type {
      margin-right: 50px;

      .inner-column, .col-title {
        border-radius: 0 15px 15px 0;
      }
    }

    .crm-gradient {
      width: 40px;
      height: 1000px;
      position: absolute;
      top: 0;
      right: 0;
      background: -webkit-linear-gradient(left, transparent, #FFF 100%), url('https://angelspartners.com/platform/images/dashboard/gradient.png') no-repeat;

      @media all and (max-width: 992px){
        width: 80px;
      }

      @media all and (max-width: 768px){
        width: 60px;
      }

      @media all and (max-width: 576px){
        width: 40px;
      }
    }
  }

  .notification {
    width: auto;
    min-width: 19px;
    height: 19px;
    font-size: 13px;
    font-weight: 400;
    padding: 2px 5px;
    line-height: 16px;
    text-transform: uppercase;
    border-radius: 10px;
    color: $color-bg-primary;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-left: none;

    &.smaller {
      height: 16px;
      font-size: 10px;
      line-height: 12px;
    }

    &.absolute {
      margin-left: 8px;
      position: absolute;
      top: -6px;
    }
    &.no-margin {
      margin: 0;
    }
  }

  .red-bg {
    background: #ff4861;
  }

  .green-bg {
    background: $bg-green;
  }

  .purple-bg {
    background: $bg-purple;
  }

  .violet-bg {
    background: $bg-violet;
  }

  .notif-followup {
    position: absolute;
    top: 32px;
    left: 52px;
  }

  .notif-smartVC {
    position: absolute;
    top: 53px;
    left: 52px;

    svg {
      width: 13px!important;
      height: 13px!important;
      margin-top: -1px;
    }
  }

  .no-results {
    position: absolute;
    top: 350px;
    left: 170px;
    z-index: 2;
  }
}
