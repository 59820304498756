.investor-items__wrap {
  overflow: visible;
  width: 100%;
}


#button__notify-startups {
  display: inline-block;
  margin-top: -87px;
  margin-right: 10px;

  @media all and (max-width: 767px) {
    display: block;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}

.btn-violet {
  background: $bg-violet;
  color: white;

  svg {
    fill: white !important;
  }
}

.btn-violet:before {
  background: $color-violet-hover;
  color: white;

  svg {
    fill: white !important;
  }
}

.btn-violet:hover {
  background: $color-violet-hover;
  color: white;

  svg {
    fill: white !important;
  }
}

.investor-items {
  width: calc(100% + 30px);
  display: flex;
  flex-wrap: wrap;

  .error-results {
    width: 100%;
    margin: auto;
    text-align: center;
  }
}

@media all and (min-width: 991px) and (max-width: 1210px) {
  .investor-items {
    width: calc(100% + 90px);
  }
}

.investor-list ~ div:nth-of-type(n+3) {
  display: none;
}

.startups-pinged {
  width: 100%!important;
}

.investor-item {
  position: relative;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  min-height: 600px;
  margin-bottom: 80px;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  @media screen and (max-width: 1450px) {
    //width: calc(50% - 30px);
  }

  @media screen and (max-width: 992px) {
    //width: calc(100% - 30px);
  }

  &:hover {
    box-shadow: 0 1px 6px #B0C2CE;
  }

  .investor-item__link {
    padding: 30px 50px 15px;
    display: block;

    &:hover {
      text-decoration: none;

      .investor-item__title {
        color: $color-accent;
      }
    }
  }

  .investor-item__link-grey {
    padding: 13px 40px;
    width: 100%;
    background-color: $color-bg-primary;
    text-align: center;
    border-radius: 0 0 10px 10px;
    display: inline-block;

    &:hover {
      text-decoration: none;

      .investor-item__title {
        color: $color-accent;
      }
    }

    .investor-item__investment-range {
      color: white;
      font-weight: 500;
    }
  }

  .investor-item__img-wrap {
    width: 100%;
    height: 200px;
    position: relative;
    display: flex;
  }

  .investor-item__img {
    display: block;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    border-radius: 100px;
  }

  .startup-item__img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 10px;
  }

  .investor-item__info {
    text-align: center;
    position: relative;
  }

  .investor-item__title {
    margin-top: 30px;
    font-weight: 500;
    transition: all 0.3s;
    font-size: 20px;
    min-height: 40px;
  }

  .investor-item__description {
    margin: 0;
    color: $color-additional;
    line-height: 18px;
    font-weight: 400;
    font-size: 16px;
    min-height: 85px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }

  .investor-item__location {
    font-size: 16px;
    font-weight: 400;
    line-height: 36px;
    margin-top: 10px;
    margin-bottom: 20px;

    svg {
      width: 25px;
      height: 25px;
      margin-top: -7px;
      margin-right: 14px;
    }
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .investor-item__color {
    height: 10px;
    width: 10px;
    margin-right: 8px;
    display: inline-block;
    border-radius: 50%;
    margin-top: 10px;
  }

  .investor-item__label {
    position: absolute;
    top: -2px;
    right : 20px;
    width: 50px;
  }

  .investor-item__label-new {
    text-transform: uppercase;
    position: absolute;
    z-index: 99;
    top: 85px;
    right: 8px;
    color: white;
    width: 70px;
    height: 35px;
    text-align: center;
    padding: 5px;
    background-color: $color-green;
  }

  .investor-item__actions {
    padding: 0 30px;
    min-height: 50px;
    text-align: center;
    margin-bottom: 15px;

    button {
      display: inline-block;
      margin-bottom: 0;

      //&:first-child {
      //  float: left;
      //}
      //
      //&:last-child {
      //  float: right;
      //}
    }
  }

  @media all and (min-width: 992px) and (max-width: 1500px) {
    .investor-item__actions {
      padding: 0 5px;
    }
  }

  .investor-item__icon-centered svg {
    margin: 0;
  }

  .investor-item__values {
    color: white;
  }
}
