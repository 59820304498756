.startup {
  .startup__information {
    padding: 30px 0;

    .startup__logo {
      position: relative;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 60px;

      img {
        width: 100%;
        max-height: 300px;
        max-width: 300px;
      }
    }

    .startup__data {
      padding: 0 30px;
      h1 {
        font-size: 24px;
        line-height: 22px;
        font-weight: 700;
        color: black;
      }

      .startup__short-desc {
        font-size: 16px;
        line-height: 25px;
        margin: 20px auto;
      }

      .startup__location {
        color: black;
        font-size: 16px;
        line-height: 22px;

        svg {
          width: 25px;
          height: 25px;
          margin-right: 10px;
          fill: black;
        }
      }

      .startup__actions {
        margin-top: 20px;
        display: flex;
      }
    }

    .startup__info {
      padding: 0 60px;

      @media all and (max-width: 991px){
        border-top: 1px solid #D5D3D3;
      }

      @media all and (min-width: 992px){
        border-left: 1px solid #D5D3D3;
      }

      p {
        color: #6F7C84;
        line-height: 25px;

        span {
          color: #243143;
        }

        a {
          color: #243143;
          text-decoration: underline;
        }
      }

      .label-primary:not(:first-child) {
        margin-left: 2px;
      }
    }

    .startup__business {
      background-color: $color-new-gray;
      color: white;
      padding: 50px;
      text-align: justify;

      h2 {
        color: white;
        font-weight: 600;
        font-size: 20px;
        line-height: 31px;
      }

      h5 {
        color: white;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        color: #CACACA;
      }
    }

    .startup__round {
      margin-top: 70px;
      box-shadow: 0 4px 40px rgba(0, 0, 0, 0.12);
      padding: 50px;

      h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 31px;
        margin-bottom: 20px;
      }

      table tr td:first-child {
        color: #6F7C84;
      }
    }

    .download-deck {
      padding: 15px 25px;
      font-size: 17px;
      text-transform: capitalize;
      border-radius: 50px;

      img {
        width: 50px;
        margin-right: 20px;
      }

      p {
        margin-top: 0;
        width: 100%;
        text-align: left;
      }
    }

    .startup__similar {
      margin-top: 70px;

      h4 {
        font-weight: 600;
        line-height: 22px;
        border-bottom: 1px solid #d3d6db;
        padding-bottom: 20px;
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
  }
}
