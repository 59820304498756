.dropdown {

  .btn.icon svg {
    height: 22px;
    width: 22px;
  }

  .btn {

    &:last-child {
      padding: 7px;

      svg {
        margin-left: 0;
        margin-right: 0;
      }
    }

    @media all and (min-width: 1500px) and (max-width: 1700px) {
      svg {
        margin: 0 0 0 3px!important;
      }
    }

    @media all and (min-width: 1330px) and (max-width: 1450px) {
      svg {
        margin: 0 0 0 5px!important;
      }
    }

    @media all and (min-width: 1200px) and (max-width: 1330px) {
      svg {
        margin: 0 0 0 0!important;
      }
    }
  }
}

.dropdown__menu {
  width: 170px;
  border-radius: 0;
  border: none;
  margin: 0;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  font-size: 13px;

  @include themify($themes) {
    background: themed('colorBackground');
  }

  button {
    transition: all 0.3s;
    padding: 7px 24px;
    cursor: pointer;

    @include themify($themes) {
      color: themed('colorText');
    }

    &:hover {

      @include themify($themes) {
        background: themed('colorHover');
        color: themed('colorText');
      }
    }
  }

  button:focus, button:active, button:active:focus {
    outline: none;
  }

  .active button {
    background-color: $color-blue;

    &:hover {
      background-color: $color-blue-hover;
    }
  }

  &.large {
    width: 190px;
  }
}

.select-type {
  .dropdown__menu {
    width: 100%;
    background: $color-new-gray;
    border-radius: 20px;

    .dropdown-item {
      color: white;
      padding: 10px 30px;
      font-size: 13px;
      line-height: 14px;

      &:hover {
        background-color: $color-new-gray-hover;
        color: $color-purple;
        font-weight: 500;
      }
    }
  }
}
