// rc-notification
.rc-notification {
  position: fixed;
  z-index: 10000;
}

.rc-notification-notice {
  display: block;
  width: auto;
  line-height: 1.5;
  vertical-align: middle;
  position: relative;
}

.rc-notification-notice-close {
  position: absolute;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  right: 35px;
  top: 5px;
  font-size: 35px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;
  text-align: center;
}

.rc-notification-notice-close-x:after {
  content: '×';
  color: #000;
}

.rc-notification-fade-enter {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.rc-notification-fade-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.rc-notification-fade-enter.rc-notification-fade-enter-active {
  animation-name: rcNotificationFadeIn;
  animation-play-state: running;

  &.right-up {
    animation-name: rcNotificationRightFadeIn;
  }

  &.right-bottom {
    animation-name: rcNotificationRightFadeIn;
  }

  &.left-up {
    animation-name: rcNotificationLeftFadeIn;
  }
}

.rc-notification-fade-leave.rc-notification-fade-leave-active {
  animation-name: rcDialogFadeOut;
  animation-play-state: running;
}

@keyframes rcNotificationFadeIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rcNotificationLeftFadeIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes rcNotificationRightFadeIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes rcDialogFadeOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.rc-notification {
  left: auto !important;
  top: 60px;
  height: 0;

  & > span {
    height: 0;
    display: block;
  }
}

.notification {
  width: 508px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
  padding: 20px 40px 20px 33px;
  text-align: left;
  position: relative;
  margin: 10px 25px;
  border-left: 6px solid white;

  &.notification--image {
    height: 106px;
    overflow: hidden;
  }

  &.notification--full-wide {
    max-width: 100vw;
    width: 100vw;
    margin: 0;
    padding: 20px 40px 20px 25px;
  }

  a {
    text-decoration: underline;
    font-weight: 500;
    color: #646777;
  }

  .collapse__title  {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  .notification__message {
    margin-top: 0;
    font-size: 14px;
    color: $color-additional;
  }

  &.notification--primary {
    background: $bg-blue;
    border-left-color: #67BAEB;

    .collapse__title, .collapse__content {
      color: $text-blue !important;
      p {
        color: $text-blue !important;
      }
    }

    .notification__message, .notification__title,
    .rc-notification-notice-close-x {
      color: $text-blue;
    }
  }

  &.notification--success {
    background: $bg-green;
    border-left-color: $color-green-hover;

    .collapse__title, .collapse__content {
      color: $color-green !important;
      p {
        color: $color-green !important;
      }
    }
  }

  &.notification--warning {
    background: $bg-warning;
    border-left-color: $color-yellow-hover;

    .collapse__title, .collapse__content {
      color: $color-warning !important;
      p {
        color: $color-warning !important;
      }
    }

    .notification__message, .notification__title,
    .rc-notification-notice-close-x {
      color: $color-warning;
    }
  }

  &.notification--danger {
    background: $bg-red;
    border-left-color: $color-red-hover;

    .collapse__title, .collapse__content {
      color: #F94586 !important;
      p {
        color: #F94586 !important;
      }
    }
  }
}

.notification__title {
  margin-bottom: 8px;
  color: #646777;
}

.notification__image {
  position: absolute;
  height: 106px;
  width: 106px;
  top: 0;
  left: 0;

  & ~ * {
    padding-left: 106px;
  }
}

.sidebar.sidebar--collapse + .container__wrap {

  .notifications-tl, .notifications-bl {
    padding-left: 0px !important;
  }
}
