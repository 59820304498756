.card-action {
  border-radius: 10px;

  .import-icons {
    display: flex;
    margin: auto 0 auto auto;
    padding-right: 0.5rem;
    border-right: 2px solid lightgrey;

    svg {
      width: 36px;
      height: 36px;
    }
  }

  .green {
    color: $color-accent;
  }

  .flex {
    display: flex;
  }

  small {
    color: $color-gray;
  }
}

a.cursor-pointer {
  cursor: pointer;
}

.csv-file-upload {
  .dropzone.dropzone--single, .dropzone__input {
    min-height: 200px;
    height: 200px !important;
  }
}

.importIcon {
  width: 22px !important;
}

.import-table {
  table-layout: fixed;

  td {
    padding: 30px 15px;
    vertical-align: top;
  }

  th {
    border-top: 0;
  }

  .colored-lines > span:nth-child(1) {
    color: $color-violet;
  }

  .colored-lines > span:nth-child(2) {
    color: $color-purple;
  }

  .colored-lines > span:nth-child(3) {
    color: $color-gray;
  }

  .colored-lines > span:nth-child(4) {
    color: $color-blue;
  }
}
