.resources {

  .card-title {
    padding: 30px 40px;

    @media all and (max-width: 990px) {
      padding: 30px 0;

      .col-12 {
        padding: 0;
      }
    }
  }

 table {
   display: block;
   padding: 30px 40px;

   @media all and (max-width: 990px) {
     padding: 30px 0;
   }

   tbody {
     display: inline-table;
     width: 100%;
   }

   tr:nth-child(odd) {
     border-bottom: 1px solid #D3D6DB;

     td {

       a {
         color: black;
         font-weight: 500;

         &:visited {
           color: #4f6896;
         }

         h4 {
           color: #4f6896;

           &:hover {
             @include themify($themes) {
               color: rgb(80, 227, 194);
             }
           }
         }
       }
     }

     &:hover + tr:nth-child(n+1) {
       background-color: rgba(0, 0, 0, 0.075);
     }
   }

   tr:nth-child(even) {
     td {
       padding-bottom: 30px;
       border: 0;
     }

     &:hover {
       background-color: white;
     }
   }

   .badge {
     padding: 8px 15px;
     border-radius: 20px;
   }
 }

  .btn-slides {
    background-color: #F9A645;
    color: white;
    font-weight: 500;
    text-transform: uppercase;

    svg {
      color: white;
      fill: white!important;
    }

    &:hover:before, &:hover,
    &:focus:before, &:focus,
    &:active:before, &:active{
      background-color: #b17733;
      color: white;
    }
  }
}

.page__resources {
  ul.d-md-inline-flex:nth-child(2) {
    width: 100%;
  }

  @media(max-width:768px) {
    ul {
      width:100%;
    }
  }

  .alert__content {
    max-width: 750px;
  }
}
