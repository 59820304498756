.label {
  padding: 3px 15px;
  background-color: $color-gray;
  border-radius: 20px;

  &.label-primary {
    background-color: $color-new-gray;
    color: white!important;
  }

  &.label-violet {
    background-color: $bg-violet;
    color: white!important;
  }

  &.label-blue {
    background-color: $color-new-blue;
    color: white!important;
  }

  &.label__notification {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 15px;
  }

  &.label__chat {
    margin-left: 12px;
    font-size: 15px;
  }
}
