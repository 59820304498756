main {
  padding: 0;
}

 //@font-face {
 //  font-family: 'Sofia Pro';
 //  src: local('Sofia Pro'), url('../../shared/fonts/Sofia/Sofia-Pro-Black-Az.otf') format('truetype')
 //}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Sofia Pro', sans-serif;
  font-size: 16px;
  line-height: 25px;
  background: #FFFFFF;

  @include themify($themes) {
    color: themed('colorText');
  }
}

* {
  box-sizing: inherit;
}

ul, ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $color-blue;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-blue-hover;
  }
}

img {
  width: 100%;
}

.hidden {
  display: none!important;
}

.pointer {
  cursor: pointer;
}

.center-block {
  margin: auto;
}

.padding-30 {
  padding: 30px;
}

.text-white:hover {
  color: #dedede !important;
}

.capitalize {
  text-transform: capitalize!important;
}

.pre-wrap {
  white-space: pre-wrap;
}

@media all and (min-width: 768px) {
  .offset-md-05 {
    margin-left: 3%;
  }
  .offset-md-04 {
    margin-left: 4%;
  }
}
