.modal {
  .modal-loader {
    position: absolute;
    background: rgba(255,255,255,0.9);
    width: 97%;
    height: 97%;
    z-index: 100;
    margin: 0;

    img {
      width: 100px;
      margin-top: 130px;
    }
  }

  .modal-cancel-subscription {
    .modal__header {
      margin-left: 15px;
      position: relative;
      text-align: center;

      .modal__investor-pic {
        width: auto;
        height: auto;
        max-width: 60px;
        max-height: 60px;
        float: left;
        border-radius: 40px;
        margin-right: 20px;
        margin-top: -10px;
      }

      .modal__close-btn {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 18px;
        background: none;
        border: none;
        cursor: pointer;
        color: #999999;

        &:hover {
          color: #646777;
        }
      }

      .modal__back-btn {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 18px;
        background: none;
        border: none;
        cursor: pointer;
        color: #999999;

        &:hover {
          color: #646777;
        }
      }

      .modal__title {
        font-weight: 600;
        font-size: 22px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-bottom: 30px;
        color: #243143;
      }
    }

    .subscription-boxes {
      margin: 55px 15px 10px;
      transition: all 0.3s ease-in-out;

      .subscription-boxes-box {
        padding: 50px 28px;
        position: relative;
        overflow: hidden;
        width: 100%;
        border: 1px solid #828F96;
        height: 100%;  // Make boxes take full height of their column
        display: flex;  // Added for vertical centering
        flex-direction: column;  // Stack children vertically
        justify-content: center;  // Center content vertically

        &.small {
          padding: 40px 28px;
          min-height: 350px;
        }

        .content-wrapper {
          flex: 1;  // Take remaining space
          display: flex;
          flex-direction: column;
          justify-content: center;  // Center vertically
        }

        &.offer {
          border: 0;
          background: linear-gradient(125deg, #A5B3FB 0%, #A5B3FB 20%, #97ABFA 50%, #59ACDE 100%);

          .subscription-boxes-title {
            color: white;
          }
        }

        &#switch-plan {
          border: 0;
          background: linear-gradient(129.9deg, #59ACDE 12.01%, #97ABFA 72.74%, #A5B3FB 72.74%), #97ABFA;

          .subscription-boxes-title {
            color: white;
          }
        }

        &#unsubscribe {
          border: 1px solid #828F96;

          .subscription-boxes-title {
            color: #243143;
          }
        }

        .subscription-boxes-title {
          font-weight: 500;
          font-size: 24px;
          text-align: center;
          margin-bottom: 20px;
          color: #243143;
          text-transform: uppercase;
        }

        .subscription-boxes-subtitle {
          font-size: 16px;
          line-height: 25px;
          text-align: center;
          color: #243143;
          max-width: 420px;
          margin: auto;
        }

        .subscription-boxes-bullets {
          font-size: 16px;
          line-height: 25px;
          color: #243143;
          max-width: 400px;
          margin: auto;
          list-style: none;
          padding-left: 0;

          li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 5px;

            &:before {
              content: "•";
              position: absolute;
              left: 0;
              color: #243143;
            }
          }
        }

        button {
          margin: 20px auto 0;
          display: block;
        }

        .discount-btn {
          font-size: 14px;
          padding: 13px 20px;

          svg {
            margin: 0 0 0 10px;
          }
        }

        &:hover {
          box-shadow: 0 1px 7px #89899b;
        }
      }
    }

    @media (max-width: 768px) {
      .modal-cancel-subscription {
        .subscription-boxes {
          margin: 30px 10px;

          > div {
            padding-left: 0;
            padding-right: 0;
          }

          .subscription-boxes-box {
            padding: 60px 20px 40px;
            min-height: auto;
            margin-bottom: 20px;

            .subscription-boxes-title {
              font-size: 22px;
            }

            .subscription-boxes-subtitle,
            .subscription-boxes-bullets {
              font-size: 14px;
            }
          }
        }
      }
    }

    // Screen transition animations
    .screen-enter {
      opacity: 0;
      transform: translateX(100%);
    }

    .screen-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: all 300ms ease-in-out;
    }

    .screen-exit {
      opacity: 1;
      transform: translateX(0);
    }

    .screen-exit-active {
      opacity: 0;
      transform: translateX(-100%);
      transition: all 300ms ease-in-out;
    }
  }


  .modal-feedback {
    .numberOfInvestors {
      .material-form__field {
        float: right;
        width: auto;
      }
    }
    .flex-direction-initial {
      flex-direction: initial !important;

      label:first-child {
        margin-left: auto;
      }
    }
    .textarea-feedback textarea:first-child {
      min-height: 70px;
    }
    .MuiRadio-colorSecondary {
      &.Mui-checked {
        color: #97ABFD;
      }
    }
  }
}
