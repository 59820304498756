@import '~rc-time-picker/assets/index.css';

.wizard {
  align-self: center;
  padding-top: 36px;
  max-width: 100%;

  hr {
    width: 100%;
  }

  .tox {
    width: 100%;
  }

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  .wizard__form {
    margin-top: 50px;
    margin-bottom: 100px;
    width: 100%;

    .wizard__toolbar {
      margin: 113px auto;

      &.small-margins {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }

    .btn__template {
      width: 251px;
      height: 50px;
      background: #273549;
      border-radius: 50px;
      border-color: #273549;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #FFFFFF;
      margin: 0 auto;
      svg {
        margin-left: 19px;
      }
    }

    .previous {
      width: 120px;

      svg {
        margin-right: 19px;
        margin-left: 0;
      }
    }

    .btn__template {
      margin-right: 0;
    }

    .btn__template:not(:first-child) {
      margin-left: 32px;
    }

    .wizard__form__followup {
      margin-top: 85px;
      width: 514px;
      .wizard__form__followup-add {
        width: 100%;
        height: 166px;
        border: 2px dashed #273549;
        box-sizing: border-box;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 22px;
        text-align: center;
        color: #273549;
        text-transform: uppercase;
      }
    }

    &.wizard__form__campaign{
      &.wizard__form__step-one, &.wizard__form__step-three {
        max-width: 677px;
      }
      &.wizard__form__step-two {
        max-width: 1155px;
      }
    }

    &.wizard__form__step-two {

      .list__investors {
        min-width: 300px;
        max-height: 150vh;
        overflow-y: auto;
        padding: 15px;
        margin-right: 30px;

        li {
          list-style: none;
        }

        .btn {
          padding: 10px 15px;
          width: 240px !important;
          border: none;
          box-shadow: 0 4px 30px rgba(36, 49, 67, 0.15);
          border-radius: 10px;
          display: block;

          @media screen and (max-width: 1350px) and (min-width: 1200px){
            width: 215px !important;
          }

          img {
            float: left;
            margin: 5px;
            width: 40px;
            height: 40px;
            object-fit: contain;
            display: block;
            border-radius: 18px;
          }

          h5 {
            display: inline-block;
            text-transform: capitalize;
            font-weight: 500;
            float: left;
            font-size: 16px;
            line-height: 20px;
            margin-top: 6px;
            text-align: left;
            margin-left: 10px;
            margin-bottom: -7px;
            max-width: 120px;
            white-space: pre-wrap;
          }

          svg {
            position: absolute;
            bottom: 22px;
            right: 14px;
            height: 25px;
            width: 25px;
            fill: #67BAEB;
          }
        }

        .paused-icon {
          position: absolute;
          top: 40px;
          left: 43px;
          width: 25px;
          height: 25px;
          background-color: white;
          border-radius: 50px;
          border: 1px solid black;

          svg {
            fill: black!important;
            position: absolute!important;
            bottom: 1px !important;
            right: 1px !important;
            height: 20px !important;
            width: 20px !important;
          }
        }
      }

      .list__connections {
        display: -moz-inline-box;
        display: -webkit-inline-box;
        width: 100%;
        max-height: 150vh;
        overflow-x: auto;
        padding: 15px;

        li {
          list-style: none;
        }

        .btn {
          padding: 10px 15px;
          width: 240px !important;
          border: none;
          box-shadow: 0 4px 20px rgba(36, 49, 67, 0.18);
          border-radius: 10px;
          display: block;
          margin-bottom: 0;

          img.profile {
            float: left;
            margin: 5px;
            width: 40px;
            height: 40px;
            object-fit: contain;
            display: block;
            border-radius: 18px;

            &.selected {
              filter: brightness(70%);
            }
          }

          img.tick {
            position: absolute;
            margin: 8px;
            width: 35px;
            height: 35px;
            object-fit: contain;
            display: block;
            border-radius: 18px;
          }

          h5 {
            display: inline-block;
            text-transform: capitalize;
            font-weight: 500;
            float: left;
            font-size: 16px;
            line-height: 20px;
            margin-top: 6px;
            text-align: left;
            margin-left: 10px;
            margin-bottom: -7px;
            max-width: 120px;
            white-space: pre-wrap;
          }

          svg {
            position: absolute;
            bottom: 25px;
            right: 14px;
            height: 25px;
            width: 25px;
            fill: #67BAEB;
            transition-duration: 0.5s;

            &:hover {
              height: 30px;
              width: 30px;
              bottom: 23px;
              right: 12px;
              filter: brightness(80%);
            }
          }
        }

        .paused-icon {
          position: absolute;
          top: 40px;
          left: 43px;
          width: 25px;
          height: 25px;
          background-color: white;
          border-radius: 50px;
          border: 1px solid black;

          svg {
            fill: black!important;
            position: absolute!important;
            bottom: 1px !important;
            right: 1px !important;
            height: 20px !important;
            width: 20px !important;
          }
        }
      }

      .wizard__toolbar {
        justify-content: space-between;

        .previous, .next {
          margin: 0;
        }


        @media all and (max-width: 690px) {
          .next {
            font-size: 12px;
          }
        }


        @media all and (max-width: 600px) and (min-width: 575px) {
          .next {
            font-size: 10px;
          }
        }
      }
    }

    .wizard__form__header {
      max-width: 575px;
      width: 100%;
      margin: 0 auto;
      display: block;
    }

    .wizard__form__body {
      width: 100%;
      .wizard__form__emails {
        width: 100%;

        @media all and (min-width: 1330px){
          -ms-flex-pack: justify !important;
          justify-content: space-between !important;
        }
        @media all and (max-width: 1329px){
          justify-content: center;
        }

        .wizard__form__email {
          width: 514px;
          position: relative;
          &.wizard__form__email-added {
            margin-top: 67px;
          }
          &.wizard__form__email-first {
            margin-top: 20px;
          }
          .day__followup__field-wrapper {
            margin-top: 35px;
            .day__followup__field {
              div {
                width: 54.5px;
                margin-left: 15px;

                input {
                  padding: 0;
                }
              }
            }
          }

          .btn__remove__email {
            border: 1px solid $color-red;
            padding: 5px;
            border-radius: 45px;
            position: absolute;
            top: 35px;
            right: 0;
            z-index: 10;

            svg {
              color: $color-red;
            }

            &:hover {
              background-color: $color-red;

              svg {
                color: white
              }
            }
          }
        }

        .wizard__form__email.linkedin, .wizard__form__followup.linkedin {
          width: 100% !important;
        }
      }
    }

    .wizard__whitelist {
      max-width: 900px;
      margin: auto;

      .bullet {
        background-color: $color-new-gray;
        color: white;
        font-size: 18px;
        border-radius: 20px;
        height: 30px;
        display: inline-block;
        margin-right: 20px;
        margin-top: -52px;
        padding: 5px 11px;
      }
    }


    .wizard__badge {
      width: 167px;
      height: 54px;
      border-radius: 40px;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 54px;
      text-align: center;
      color: #000000;
      margin-right: 36px;
      margin-bottom: 27px;

      &.wizard__badge-violet {
        background-color: $color-purple;
      }

      &.wizard__badge-green {
        background-color: $bg-green;
      }

      &.wizard__badge-grey {
        background-color: $color-light-gray;
      }

      &.wizard__badge-blue {
        background-color: $color-blue;
      }

    }

    .btn-block {
      margin: 20px auto;

      svg {
        margin-left: calc(50% - 57px);
      }
    }

    input {
      border: none;
      border-bottom: 1px solid #9894A9;
    }
  }

  .wizard__form-wrapper {
    display: flex;
    justify-content: center;

    .time-input .form__form-group-field{
      padding: 0!important;
    }

    .days-list {
      margin: 10px auto;
      display: inline;
      width: 415px;
    }

    .timePicker {
      background-color: $color-bg-primary;
      color: #fff;
      border-radius: 50px;
      width: 300px;
      height: 50px;
      border: none;

      input {
        height: 50px;
        border: none;
        color: white;
      }

      .rc-time-picker-clear {
        right: 45px;
        top: 13px;
      }

      .rc-time-picker-clear-icon:after {
        color: red;
        font-size: 20px;
      }
    }

    .rc-time-picker-panel-inner {
      width: 281px;
    }

    .react-select {
      width: 300px;
      float: right;

      .react-select__control {
        background-color: $color-bg-primary;
        color: #fff;
        border-radius: 50px;
        width: 300px;
        height: 50px;
        border: none;

        .react-select__placeholder {
          color: white;
        }

        .react-select__dropdown-indicator,
        .css-1okebmr-indicatorSeparator {
          color: #67BAEB;
        }

        .react-select__single-value {
          color: white;
        }
      }
    }
  }

  .wizard__steps {
    width: 100%;
    max-width: 609px;
    display: flex;
    align-items: center;
    margin: 0 auto 97px;

    &.wizard__extra-margin {
      margin-bottom: 120px;
    }
  }

  .wizard__step {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: none;
    cursor: not-allowed !important;


    .wizard_step_counter {
      width: 60px;
      height: 60px;
      line-height: 60px;
      border-radius: 60px;
      background: #FFFFFF;
      border: 1px solid #8D9AA1;
      box-sizing: border-box;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      color: #8D9AA1;
    }

    p {
      position: absolute;
      top: 75px;
      transition: all 0.3s;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 22px;
      text-align: center;
      color: #000000;
    }

    &.wizard__step--active {

      .wizard_step_counter {
        background: #97ABFA;
        color: #FFFFFF;
        border: none;
      }
    }

    &.wizard__step--clickable {
      cursor: pointer !important;
    }
  }

  .wizard__title {
    margin-bottom: 26px;
    margin-left: auto;
    margin-right: auto;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 32px;
    text-align: center;
    color: #000000;
  }

  .wizard__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #828F96;
    margin-bottom: 46px;
  }

  .wizard__description {
    color: $color-additional !important;
    margin: 0;
    max-width: 80%;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
  }
}

.squared-corner-theme {

  .wizard__step {
    border-radius: 0;
  }
}

.react-select__control {
  border-radius: 10px;

  .react-select__multi-value {
    background-color: $color-new-blue;
    border-radius: 20px;
    padding: 0 5px;

    div {
      color: white;
    }
  }
}
