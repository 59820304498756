.settings {
  .mail-boxes {
    margin-bottom: 80px;
    margin-top: 50px;

    .card {
      box-shadow: 0 4px 40px rgba(36, 49, 67, 0.13);
      border-radius: 15px;
      padding-bottom: 0;

      .panel__body {
        padding-bottom: 40px;
      }

      &:hover {
        box-shadow: 0 4px 40px rgba(36, 49, 67, 0.33);
        background-color: #e2e3e6;

        .panel__body {
          background-color: #e5e6e9;
        }
      }

      &.forbidden {
        background-color: #F8D7DA;
        cursor: not-allowed;
      }

      .card-body {
        .mailbox__img-container {
          text-align: center;
          height: 110px;

          img {
            max-width: 80px;
            margin: 10px auto 40px;
          }
        }

        .mailbox__info {

          h5 {
            font-size: 18px;
            line-height: 22px;
            color: black;
            text-align: center;
            height: 50px;
          }

          p {
            font-size: 13px;
            line-height: 20px;
            height: 100px;
          }

          button {
            margin: 23px auto 0;
          }
        }
      }
    }
  }

  .nav-tabs {
    width: 150px!important;
    min-width: 150px;
  }

  .tab-content {
    min-width: 70%;
    padding-left: 40px;

    .form__form-group-input-wrap {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}



.settings__card {
  padding: 30px;
  margin: auto;

  @media all and (min-width: 1200px) {
    max-width: 500px;
  }
  img {
    width: auto;
    height: auto;
    margin: 40px auto;
    max-width: 300px;
    max-height: 300px;
  }

  h4 {
    color: black;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
  }

  .card__location {
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;

    svg {
      color: black;
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }

  table {
    margin-top: 40px;

    tr td {
      border: none;
    }

    tr td:first-child {
      font-size: 13px;
      line-height: 20px;
      color: #8D9AA1;
    }

    tr td:last-child {
      font-size: 13px;
      line-height: 20px;
      color: #243143;
    }
  }
}

.settings__actions {
  margin-top: 16px;

  .text-danger {
    color: #B2456C!important;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

  .btn {
    margin: 20px auto;
  }
}

.company {
  .nav-tabs {
    width: 22%!important;
    min-width: 150px!important;
  }

  .tab-content {
    width: 78%;
  }

  .material-form {
    h5 {
      margin: 20px 0 10px 0;
    }
  }
}
