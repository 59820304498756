.modal {
  .modal-dialog {
    max-width: 385px;
    border-radius: 10px;

    &.large {
      max-width: 485px;
    }
    &.x-large {
      max-width: 600px;
    }
    &.xx-large {
      max-width: 1000px;
    }

    &.xxx-large {
      max-width: 90%;
    }
  }

  .modal__tabs {

    .tabs {
      margin-top: 100px;

      .nav-tabs {
        width: 265px!important;

        .nav-link {
          padding-left: 0;
        }
      }

      .tabs-content {
        width: 100%;
      }
    }

    .modal__body {
      margin: 10px auto 20px;
      padding: 0 40px;
    }
  }

  .p-20 {
    padding: 20px !important;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px;

    @include themify($themes) {
      background-color: themed('colorBackground');
      color: themed('colorText');
    }

    .modal__header {
      text-align: left;

      .line{
        width: 80px;
        height: 3px;
        margin-top: 15px;
      }
    }

    .modal__body {
      padding: 50px 10px;
    }

    .modal__tabs {
      .nav-link {
        padding-right: 30px!important;
      }
      .tab-pane {
        padding: 10px 20px 10px 45px!important;
      }
    }

    .modal__list {
      padding: 35px 20px 20px 20px;

      li {
        list-style: none;

        .btn {
          padding: 10px 15px;
          width: 100%;
          border: none;
          box-shadow: 0 4px 30px rgba(36, 49, 67, 0.15);
          border-radius: 10px;
          display: block;

          small {
            font-size: 12px;
            text-transform: none;
          }

          svg:not(.list-multi):not(.tooltip-reco) {
            position: absolute;
            bottom: 22px;
            right: 14px;
            height: 25px;
            width: 25px;
          }

          svg.tooltip-reco {
            float: left;
            margin: 4px -6px 0px 2px;
          }

          img {
            float: left;
            margin: 5px;
            width: 40px;
            height: auto;
            display: block;
            border-radius: 18px;
          }

          h5 {
            display: inline-block;
            text-transform: capitalize;
            font-weight: 500;
            float: left;
            font-size: 16px;
            line-height: 20px;
            margin: 6px 0 0 10px;
            text-align: left;
            white-space: pre-wrap;
            width: auto;
          }

          p {
            margin: -22px 0 0 60px;
            font-size: 11px;
            line-height: 16px;
            font-weight: 300;
            float: left;
            clear: both;
            width: 140px;
            text-align: left;
          }

          span {
            color: #cccccc;
            font-size: 9px;
            position: absolute;
            bottom: 12px;
            right: 30px;
            width: 140px;
          }
        }
      }
    }

    .modal__list.multiple {
      max-height: 120vh;
      overflow: scroll;
    }
  }

  .modal__close-btn {
    position: absolute;
    top: 15px;
    right: 17px;
    font-size: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    padding: 0;
    border: none;
    background: transparent;

    @include themify($themes) {
      color: themed('colorIcon');
    }
  }

  .modal__title-icon {
    width: 40px;
    height: 40px;
    font-size: 40px;
    float: left;
    margin-right: 25px;
    margin-top: -10px;

    &.inline-icon{
      font-size: 40px;
      margin: 10px 20px -20px -16px;
    }
  }

  .modal__footer {
    margin-top: 20px;
    margin-bottom: 0;
    align-self: flex-end;

    .btn {
      margin-bottom: 0;

      &:first-child {
        margin-right: 40px!important;
      }
    }
  }

  .modal_cancel {
    background-color: transparent!important;
  }

  .modal__line {
    width: auto;
    margin: 20px auto 10px 0;
  }

  .modal-dialog--primary,
  .modal-dialog--security {

    .modal__title-icon {
      color: $color-blue;
    }
  }

  .modal-dialog--success {

    .modal__title-icon {
      color: $color-accent;
    }
  }

  .modal-dialog--warning {

    .modal__title-icon {
      color: $color-yellow;
    }
  }

  .modal-dialog--danger {

    .modal__title-icon {
      color: $color-red;
    }
  }

  .modal-dialog--colored {
    color: white;

    .modal__title-icon {
      color: white;
    }

    .modal__close-btn {
      color: white;
    }

    .modal__body a {
      color: white;
      font-weight: 600;
      text-decoration: underline;
    }

    .modal__footer {

      button:first-child:not(.modal_ok) {
        color: #ffffff;
        background-color: rgba(255, 255, 255, 0.3);
        border-color: #ffffff;

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: $text-blue;
        font-weight: 600;
      }
    }

    &.modal-dialog--primary .modal-content,
    &.modal-dialog--security .modal-content{
      background-color: $color-blue;
    }

    &.modal-dialog--success .modal-content {
      background-color: $color-accent;
    }

    &.modal-dialog--warning .modal-content {
      background-color: $color-yellow;
      font-weight: 500;
    }

    &.modal-dialog--danger .modal-content {
      background-color: $color-red;
    }
  }

  .modal-dialog--header {
    max-width: 520px;

    .modal-content {
      padding: 0;
      text-align: left;

      .modal__header {
        padding: 40px 50px 25px 48px;

        .modal__title {
          font-weight: 600;
          font-size: 20px;
          line-height: 22px;
          text-align: left;
        }

        .modal__close-btn {
          color: white;
        }

        .modal__line {
          margin-top: 20px;
        }
      }

      .modal__body {
        padding: 40px 30px 30px;

        .modal__footer {
          margin-bottom: 40px;
          float: right;

          .btn {
            margin: 0;
          }
        }
      }
    }

    &.modal-dialog--primary .modal__header,
    &.modal-dialog--security .modal__header{
      background-color: $color-blue;

      .modal__title, .modal__title-icon {
        color: white;
      }
    }

    &.modal-dialog--success .modal__header {
      background-color: $color-accent;

      .modal__title, .modal__title-icon {
        color: white;
      }
    }

    &.modal-dialog--warning .modal__header {
      background-color: $color-yellow;

      .modal__title, .modal__title-icon {
        color: $color-warning;
      }
    }

    &.modal-dialog--danger .modal__header {
      background-color: $color-red;

      .modal__title, .modal__title-icon {
        color: white;
      }
    }
  }
}
