.warm-intro {
  .btn {
    position: absolute;
    right: 10px;
    top: 308px;
    z-index: 10;
  }

  @media all and (max-width: 1200px) {
    .btn {
      top: 380px;
    }
  }
}

.btn-disabled {
  margin-left: calc(100% - 200px);
}

.btn-choose {
  margin-left: calc(100% - 200px);
}

@media all and (min-width: 1199px) {
  .btn-choose {
    margin-left: 43%;
  }
}
