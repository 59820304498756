.coinvestor__link, .simstartup__link {
    .coinvestor__img, .simstartup__img {
        width: 48px;
        height: 48px;
        object-fit: cover;
    }

    &:hover {
        background-color: #E1E1E1;
    }
}
