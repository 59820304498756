.project-member__btn {
  max-width: 66px;
  display: inline-block;
  margin-left: 30px;
  margin-bottom: 0;
}

.project-member__avatar-wrap {
  width: 40px;
  min-width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;

  img {
    height: 100%;
    min-width: 100%;
  }
}

.project-member__name {
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 3px;
}

.project-member__post {
  color: $color-additional;
  line-height: 15px;
  font-size: 11px;
  margin: 0;
  @media (max-width: 1100px) and (min-width: 990px) {
    font-size: 10px;
  }

}
