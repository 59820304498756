.form {
  display: flex;
  flex-wrap: wrap;

  input, textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;

    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    @include themify($themes) {
      border-bottom: 1px solid themed('colorFieldsBorder');
      color: themed('colorText');
    }

    &[disabled] {

      @include themify($themes) {
        background: themed('colorBackgroundBody');
      }

      &:focus, &:active {

        @include themify($themes) {
          border-color: themed('colorBorder');
        }
      }
    }

    &:focus, &:active {
      outline: none;
      border-color: $color-accent;
    }
  }

  textarea {
    min-height: 85px;
    border: 1px solid #c8c8c8;
  }

  &.form--horizontal {

    .form__form-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .form__form-group-field {
      width: calc(100% - 80px);
      padding-left: 10px;
      margin-left: 80px;


      @media screen and (min-width: 550px) {
        &.medium {
          width: calc(100% - 250px);
        }
        &.large {
          width: calc(100% - 350px);
        }
        &.days {
          width: 95px;
          margin: 7px auto 7px 0;
        }
      }
    }

    .form__form-group-label {
      width: 80px;
      max-height: 32px;
      margin: auto 0;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      color: #828F96;


      & + .form__form-group-field {
        margin-left: 0;
      }

      &.large, &.medium {
        max-height: 82px;
      }
    }

    .form__form-group-description {
      margin-left: 90px;
    }

    .form__button-toolbar {
      margin-left: 0;
    }

    .form__form-group-input-wrap--error-above {
      margin-bottom: 15px;

      .form__form-group-error {
        top: -28px;
      }
    }

    @media screen and (min-width: 480px) {

      .form__form-group-label {
        width: 120px;

        @media screen and (min-width: 550px) {
          &.medium {
            width: 250px;
          }
          &.large {
            width: 350px;
          }
        }
      }

      .form__form-group-field {
        width: calc(100% - 120px);
        margin-left: 50px;
        padding-left: 20px;
      }

      .form__form-group-description, .form__button-toolbar {
        margin-left: 140px;
      }
    }
  }

  &.form--preview {
    display: flex;

    & > div:nth-child(2) {
      margin-right: 50px;
    }

    .form__form-group {
      margin-bottom: 10px;
      width: auto;
      min-height: 18px;
    }

    .form__select-color {
      display: flex;
      flex-wrap: wrap;
      max-width: 84px;
      margin-right: 40px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  &.form--justify {
    display: flex;
    flex-wrap: wrap;

    .form__form-group {
      width: 33.3333%;
    }

    .form__button-toolbar {
      width: 100%;
    }
  }

  .form__form-group-select {
    width: 100%;
    height: 32px;
    font-size: 12px;

    .Select-control {
      height: 32px;
      border-radius: 0;
      transition: all 0.3s;
      background: transparent;

      @include themify($themes) {
        border: 1px solid themed('colorFieldsBorder');
      }
    }

    .Select-placeholder, .Select-input {
      height: 30px;
    }

    .Select-input {
      width: 100%;
    }

    input {
      width: 100% !important;
      border: none;
      padding: 0;
    }

    .Select-multi-value-wrapper {

      .Select-input {
        width: inherit;
      }

      .Select-value {
        background: transparent;
        border-color: $color-blue;
      }

      .Select-value-label {

        @include themify($themes) {
          color: themed('colorText') !important;
        }
      }

      .Select-value-icon {
        border-color: $color-blue;
      }
    }

    .Select-menu-outer {
      top: calc(100% + 1px);
      border-radius: 0;
      box-shadow: none;
      font-size: 12px;
      animation: open 0.3s ease-in-out;
      overflow: hidden;

      @include themify($themes) {
        border: 1px solid themed('colorBorder');
        background: themed('colorBackground');
      }
    }

    @keyframes open {
      0% {
        max-height: 0
      }
      100% {
        max-height: 200px
      }
    }

    .Select-option {
      transition: all 0.3s;
      border-radius: 0;
      display: flex;

      @include themify($themes) {
        background: themed('colorBackground');
        color: themed('colorText');
      }

      &.is-focused {
        @include themify($themes) {
          background: themed('colorHover');
        }
      }
    }

    &.is-focused, &.is-focused:not(.is-open) {

      .Select-control {
        border-color: $color-accent;
        box-shadow: none;
        background: transparent;
      }
    }

    .form__form-group-select-color {
      display: block;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: auto 0 auto 5px;
    }
  }

  .checkbox-btn.checkbox-btn--colored-click .checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom {
    border-color: #97ABFA;
    background-color: #97ABFA;
  }

  .checkbox-btn.checkbox-btn--colored-click .checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom svg {
    fill: black;
    width: 28px;
    margin-bottom: 10px;
    height: 24px;
  }

  .form__advanced-options {
    width: 100%;
    p {
      margin-top: 4px;
      margin-left: 6px;
    }

    .form__form-group {
      width: 100%;
      padding-right: 0;

      .form__form-group-field {
        width: calc(100% - 295px);
      }
    }
  }
}

.form__half {
  width: calc(50% - 15px);
  height: 100%;

  &:first-child {
    margin-right: 30px;
  }

  .form__button-toolbar {
    float: right;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0;
  }
}

.form__form-group {
  margin-bottom: 20px;
  width: 100%;
  position: relative;

  &.light input{
    border: 1px solid #f2f4f7;
    color: #646777;
  }
}

.form__form-group--address {

  input:last-child {
    margin-left: 15px;
    width: 70%;
  }
}

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
}

.form__form-group-label {
  margin-bottom: 4px;
  display: inline-block;

  @include themify($themes) {
    color: themed('colorText');
  }

  span {
    color: #dddddd;
  }
}

.form__form-group-button {
  padding: 6px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s;

  @include themify($themes) {
    background: themed('colorFieldsBorder');
    border: 1px solid themed('colorFieldsBorder');
  }

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }

  &.active {
    background: $color-accent;
    border: 1px solid $color-accent;

    svg {
      fill: #ffffff;
    }
  }
}

.form__form-group-file {

  label {
    border-radius: 2px;
    line-height: 18px;
    font-size: 12px;
    padding: 4px 20px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;

    @include themify($themes) {
      border: 1px solid themed('colorFieldsBorder');
      color: themed('colorText');
    }

    &:hover {

      @include themify($themes) {
        background: themed('colorFieldsBorder');
      }
    }
  }

  span {
    padding-left: 10px;
  }

  input {
    display: none;
  }
}

.form__form-group-icon {
  padding: 6px;
  height: 32px;

  @include themify($themes) {
    background: themed('colorFieldsBorder');
    border: 1px solid themed('colorFieldsBorder');
  }

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
}

.form__form-group-description {
  font-size: 12px;
  color: $color-additional;
  line-height: 13px;
  margin-top: 2px;
}

.form__button-toolbar {
  margin-top: 10px;
}

.form__form-group-input-wrap {
  width: 100%;
}

.form__form-group-error {
  font-size: 13px;
  line-height: 13px;
  color: $color-red;
  margin-bottom: -5px;
  display: block;
  margin-top: 10px;

  &.error-large {
    font-size: 15px;
    line-height: 15px;
  }
}

.form__form-group-input-wrap--error-above {

  .form__form-group-error {
    position: absolute;
    margin: 0;
    right: 0;
    top: 0;
    padding: 5px 10px;
    background: #ffbcbc;
    border-radius: 3px;

    &:after {
      content: '';
      position: absolute;
      right: 10px;
      bottom: -8px;
      border: 4px solid transparent;
      border-top: 4px solid #ffbcbc;
    }
  }
}

.form__form-group-date-cvc {
  display: flex;
  width: 100%;

  .form__form-group-date {
    width: 100%;
    margin-right: 20px;
  }

  .form__form-group-cvc {
    max-width: 280px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-date {
      margin-right: 0;
    }

    .form__form-group-cvc {
      max-width: 100%;
    }
  }
}

.form__form-group-id-category {
  width: 100%;
  display: flex;

  .form__form-group-id {
    min-width: 100px;
    width: 40%;
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-id {
      margin-right: 0;
      width: 100%;
    }
  }
}

.form__form-group-price-discount {
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  & > div {
    margin-bottom: 0;
  }

  .form__form-group-price {
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-price {
      margin-right: 0;
    }
  }
}

.form__chat .dropzone{
  height: 200px!important;
}

.form__chat .dropzone .dropzone__input{
  min-height: 198px!important;
}

// Custome made for search ext investors
.input-keywords, .input-location {

  &.long-input-keywords {
    width: 100%;
  }

  small {
    color: #999999;
  }
}

.helper-search {
  font-weight: 400;
  font-size: 13px;
}

.toolbar > div {
  display: inline-block;
}

.input-search {
  color: #828E96!important;
  padding: 10px 30px!important;
  border: 1px solid #BBC1C5!important;
  height: 50px!important;
  min-width: 130px;
  display: block;
  box-sizing: border-box;
  border-radius: 50px;

  &.input-location {
    width: 155px;
  }

  &.long-input {
    width: 620px!important;
  }

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #828E96!important;
    opacity: 1!important; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #828E96!important;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #828E96!important;
  }
}

.btn-union {
  height: 55px;
  width: 55px;
}

.btn-union.reverse {
  transform: rotate(180deg);
}

span.reverse {
  transform: rotate(180deg);
}

#industry-select {
  @media screen and (min-width: 1200px) and (max-width: 1400px) {
    margin-left: 20px !important;
    width: 97% !important;
  }
}

.input-industry {
  min-height: 42px;
  max-height: 120px;

  .react-select__value-container--is-multi {
    min-height: 42px;
    max-height: 120px;
    overflow: scroll;
    padding: 5px 12px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .react-select__value-container--is-multi::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .react-select__value-container--is-multi {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .react-select__control {
    border: none;
    min-height: 45px;
    max-height: 120px;
    overflow: hidden;
    background-color: $color-new-gray;
    border-radius: 50px;
    color: white;
    padding: 6px 20px;
  }

  .react-select-disabled {
    .react-select__control {
      background-color: grey !important;
    }
  }

  .form__form-group-input-wrap {
    max-height: 31px;
  }

  .react-select__indicator {
    color: #70bbfd;
  }

  .react-select__indicator-separator {
    background-color: white;
  }

  .react-select__placeholder {
    color: white;
    position: relative;
    margin: 5px auto -19px;
  }

  &.input-filters {
    .react-select__placeholder {
      @media all and (min-width: 1200px) and (max-width: 1255px) {
        margin: 17px auto -42px;
      }
      @media all and (min-width: 992px) and (max-width: 1166px) {
        margin: 17px auto -42px;
      }
    }
  }
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.apply-filters {
  max-width: 320px;

  p {
    color: white;
  }
}

.time .form__form-group-field {
  width: 300px!important;
  margin-left: auto!important;
  margin-right: 0px!important;
}

.time .form__form-group-input-wrap {
  margin-top: -30px;
  margin-bottom: 20px;

  .rc-time-picker-input {
    border: 1px solid #c0c0c0;
  }
}

.form-report {
  .radio-btn {
    margin: 10px 0px;
  }
}

.stripe-input {
  padding: 7px 12px;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.MuiInput-underline:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15)!important;
}
