.alert {
  border-radius: 0;
  position: relative;
  margin-bottom: 10px;
  padding: 0;
  display: flex;
  height: fit-content;


  .alert__content {
    padding: 35px 50px;

    p {
      font-weight: 300;

      strong {
        font-weight: 700;
      }
    }
  }

  &.fade {
    opacity: 1;
  }

  &:last-child {
    margin-bottom: 0;
  }

  p {
    color: #ffffff;
    margin-top: 0;
  }

  .close {
    color: white;
    opacity: 1;
    font-weight: 100;
    font-size: 14px;
    line-height: 14px;
    position: absolute;
    top: calc(50% - 7px);
    text-shadow: none;
    right: 20px;

    &:focus, &:active, &:active:focus {
      outline: none;
    }
  }

  &.alert-info {
    background-color: $bg-blue;
    border: solid #67BAEB;
    border-width: 0 0 0 5px;

    &.alert--bordered .alert__icon {
      background-color: $color-blue;
    }

    &.alert--colored .alert__icon {
      background-color: #87c3f7;
    }

    &.alert--neutral .alert__icon svg {
      fill: $color-blue;
    }

    p {
      color: $text-blue;
    }
  }

  &.alert-purple {
    background-color: $color-violet;
    box-shadow: 0 4px 30px rgba(36, 49, 67, 0.08);
    border: solid darken($color-violet, 10%);
    border-width: 0 0 0 5px;

    &.alert--bordered .alert__icon {
      background-color: $color-violet;
    }

    &.alert--colored .alert__icon {
      background-color: $color-violet-hover;
    }

    &.alert--neutral .alert__icon svg {
      fill: $color-violet;
    }

    p {
      color: white;
    }
  }

  &.alert-success {
    background-color: $color-accent;
    border: solid darken($color-accent, 10%);
    border-width: 0 0 0 5px;

    &.alert--bordered .alert__icon {
      background-color: $color-accent;
    }

    &.alert--colored .alert__icon {
      background-color: #55e5bb;
    }

    &.alert--neutral .alert__icon svg {
      fill: $color-accent;
    }
  }

  &.alert-warning {
    background-color: lighten($color-yellow, 20%);
    border: 0 solid $color-yellow-hover;
    border-left-width: 5px;

    &.alert--bordered .alert__icon {
      background-color: $color-yellow;
    }

    &.alert--colored .alert__icon {
      background-color: white;
    }

    &.alert--neutral .alert__icon svg {
      fill: white;
    }

    p {
      color: #856404;
    }
  }

  &.alert-danger {
    background-color: $bg-red;
    border: solid $color-red-hover;
    border-width: 0 0 0 5px;
    color: #F94586;

    p {
      color: #F94586;
    }

    &.alert--bordered .alert__icon {
      background-color: $color-red-hover;
    }

    &.alert--colored .alert__icon {
      background-color: #F94586;
    }

    &.alert--neutral .alert__icon svg {
      fill: #F94586;
    }
  }

  &.alert--bordered {
    background-color: transparent;

    p {
      @include themify($themes) {
        color: themed('colorText');
      }
    }

    .close {
      color: $color-additional;
    }
  }

  &.alert--neutral {
    background-color: transparent;
    border-color: #f2f4f7;

    p {
      @include themify($themes) {
        color: themed('colorText');
      }
    }

    .close {
      color: $color-additional;
    }

    svg {
      @include themify($themes) {
        fill: themed('colorText');
      }
    }

    .alert__icon {
    }
  }

  &.alert--white {
    border-color: white!important;

    .alert__icon {
      border-right: 1px solid white;
    }
  }

  &.alert--margin {
    margin: 25px auto;
    margin-bottom: 25px!important;
  }

  .page-subhead {
    margin-bottom: 0px;
  }
}

.alert__icon {
  padding: 8px;
  display: flex;

  svg {
    fill: #ffffff;
    margin: auto 0;
  }
}

.alert-modal {
  margin-top: 50px
}

.alert--chat {
  margin-bottom: -80px!important;
  z-index: 1;
}
