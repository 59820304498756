.extinvestor-list {

  #button__add-all-investors {
    display: inline-block;
    margin-top: -87px;
    margin-right: 10px;

    @media all and (max-width: 767px) {
      display: block;
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }

  .extinvestor__item__wrapper {
    position: relative;
    margin-top: 35px;

    .extinvestor__item__link {
      color: #646777 !important;
      cursor: pointer;

      .card {
        padding-bottom: 0;
        box-shadow: 0 4px 40px rgba(0, 0, 0, 0.09);
        border-radius: 10px;

        .extinvestor__item {
          display: flex;
          min-height: 250px;

          .row {
            width: 100%;
          }

          .extinvestor__info {
            flex-grow: 1;
            overflow: hidden;

            .border-right {
              border: none!important;

              @media (min-width: 1180px) {
                border-right: 1px solid #dee2e6!important;
              }
            }

            .extinvestor__companyRole {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }

            .see-more {
              cursor: pointer;
              height: 50px;
              line-height: 65px;
              text-align: center;
              width: 100%;
              position: absolute;
              left: 0;
              bottom: -5px;
              color: #646777;
              background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 125%); /* FF3.6-15 */
              background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 125%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to top, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 125%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

              svg {
                width: 18px;
                height: 18px;
              }
            }

            .see-less {
              cursor: pointer;
              height: 50px;
              line-height: 65px;
              text-align: center;
              width: 100%;
              position: absolute;
              left: 0;
              bottom: -5px;
              color: #646777;

              svg {
                width: 18px;
                height: 18px;
              }
            }

            h4 {
              line-height: 24px;
              font-weight: 600;
              font-size: 14px;
            }

            h2 {
              font-size: 20px;
              line-height: 22px;
              font-weight: 500;
              display: inline;
            }

            .extinvestor__additional {
              display: block;
              margin: 20px 0;
            }

            @media all and (max-width: 991px) {
              .extinvestor__additional {
                display: block;
                margin-left: 0px;
                margin-top: 10px;
              }
            }

            .extinvestor__priority-indicator {
              background-color: $color-accent;
              height: 10px;
              width: 10px;
              border-radius: 50%;
              display: inline-block;
              vertical-align: middle;
              align-self: center;
              flex-shrink: 0;

              &.low {
                background-color: $color-accent;
              }

              &.medium {
                background-color: $color-yellow;
              }

              &.high {
                background-color: $color-red;
              }
            }

            .extinvestor-links {
              width: 30px;
              height: 30px;
              margin-right: 15px;
              margin-top: -6px;
            }

            @media all and (max-width: 600px) {
              .extinvestor-links {
                margin-top: 6px;
              }
            }
          }

          .extinvestor__content {
            margin-right: 20px;
            min-height: 230px;

            @media all and (max-width: 700px) {
              margin-top: 50px;
            }

            @media all and (max-width: 600px) {
              margin-top: 90px;
            }

            @media all and (min-width: 700px) and (max-width: 1150px) {
              min-height: 260px;
            }

            h4 {
              display: inline-block;
            }

            .extinvestor__report {
              position: absolute;
              top: -10px;
              right: -17px;
              color: #B2456C;
              cursor: pointer;
            }

            .extinvestor__description-short {
              display: block;
              line-height: 25px;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: justify;
            }

            .extinvestor__description,
            .extinvestor__contacts {
              padding-right: 0px;

              @include themify($themes) {
                color: themed('colorText');
              }

              ul {
                padding-left: 0px;
                width: 100%;
              }

              .bottom-15 {
                margin-bottom: 15px;
                display: inline-block;
              }

              li {
                height: 45px;
                margin-top: 10px;
                list-style: none;
              }

              img {
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
                display: inline;
                float: left;
                margin: auto 15px auto auto;
                max-height: 40px;
                max-width: 40px;
                width: auto;
                height: auto;
                border-radius: 5px;
              }
            }

            @media all and (min-width: 992px) {
              .extinvestor__contacts {
                margin-top: -45px;
              }
            }
            @media all and (max-width: 768px) {
              .extinvestor__contacts {
                margin-top: 30px;
              }
            }
          }

          .extinvestor__firm, .extinvestor__popular {
            padding: 5px 16px;
            text-align: center;
            border-radius: 20px;
            line-height: 20px;
            width: 100px;
            height: 30px;
            font-size: 12px;
            font-weight: 500;
            color: $color-bg-primary;
            text-transform: uppercase;
            display: block;
            margin: 13px auto auto;
          }

          .extinvestor__firm {
            background-color: rgba(76, 225, 182, 0.4);
          }

          .extinvestor__popular {
            background-color: rgba(103,186,235, 0.3);
          }

          .extinvestor__img-wrapper {
            display: block;
            margin: 0 20px auto auto;
            width: 100%;
            height: auto;

            .extinvestor__img {
              display: block;
              margin: auto;
              width: 100%;
              max-width: 150px;
              border-radius: 4px;
              margin-bottom: 15px;
            }
          }

          .extinvestor__contact-img {
            float: left;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
            display: block;
            margin: auto;
            max-height: 25px;
            max-width: 25px;
            width: auto;
            height: auto;
            border-radius: 5px;
            margin-right: 10px;
          }
        }
      }
    }

    .extinvestor__buttons {
      position: absolute;
      z-index: 100;
      width: auto;

      top: 60%;
      margin-left: 100px;

      @media all and (min-width: 575px) {
        top: 220px;
        margin-left: 20%;
      }

      @media all and (min-width: 600px) {
        top: 160px;
        margin-left: 35%;
      }

      @media all and (min-width: 700px) {
        top: 240px;
        margin-left: 20%;
      }

      @media all and (min-width: 1115px) {
        top: 215px;
        margin-left: 19%;
      }

      @media all and (min-width: 1150px) {
        top: 190px;
        margin-left: 20%;
      }

      @media all and (min-width: 1460px) {
        top: 174px;
        margin-left: 230px;
      }

      button {
        display: inline-block;

        &:first-child {
          margin-right: 10px;
          @media (min-width: 1100px) {
            margin-right: 20px;
          }
          @media (min-width: 1500px) {
            margin-right: 30px;
          }

          @media all and (max-width: 1300px) and (min-width: 992px) {
            padding-left: 8px;
            padding-right: 8px;
          }
        }
      }
    }
  }
}
