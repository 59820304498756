.Campaigns__table {
  td {
    padding: 40px 0 40px 15px;
    vertical-align: middle;
  }

  th {
    border-top: 0;
  }

  .paused {
    color: $color-red;
    font-size: 10px;
    display: flex;
    line-height: 1.5;
  }

  .play-icon {
    width: 45px;
    height: 45px;
    color: #67BAEB;
    fill: #67BAEB;
  }

  .draft-campaign, .draft-campaign:hover {
    color: grey;
  }

  .draft-campaign:first-child > td > a > h4 {
    color: grey !important;
  }
}

.campaigns-filters {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  img {
    width: 100%;
  }
}

.campaigns-filters__btns {
  width: 100%;
  text-align: left;
  display: inline-flex;
}

.campaigns-filters__btn {
  margin: 20px 30px 20px 5px;
  background: transparent;
  padding: 0;
  text-transform: uppercase;
  font-size: 14px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;

  &:focus, &:active {
    outline: none;
  }

  &:hover {
    color: darken(#999999, 10%);
  }

  &.campaigns-filters__btn--active {
    color: $color-violet;
  }

  svg {
    margin-top: -3px;
  }
}

.badge {
  color: black;
  margin-left: 5px;
  padding: 6px 9px;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 400;

  &.badge-success {
    background-color: $color-accent;
  }

  &.badge-warning {
    background-color: $color-yellow;
  }

  &.badge-primary {
    background-color: $color-blue;
  }

  &.badge-danger {
    background-color: $color-red;
  }

  &.badge-disabled {
    background-color: #dddddd;
  }

  &.badge-enabled {
    background-color: $color-purple;
  }
}

.Campaigns__empty, .Broker__empty {
  .Campaigns__steps {
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.12);
    padding: 55px 55px 50px;
    margin: 30px 0;

    @media all and (min-width: 768px){
      min-height: 550px;
    }

    @media all and (min-width: 874px){
      min-height: 500px;
    }

    @media all and (min-width: 1050px){
      min-height: 400px;
    }

    @media all and (min-width: 1200px){
      min-height: 500px;
    }

    @media all and (min-width: 1600px){
      min-height: 400px;
    }

    h2 {
      font-weight: 700;
      font-size: 45px;
      line-height: 22px;
      color: #FFFFFF;
      margin-bottom: 20px;
    }

    h3 {
      font-weight: 500;
      font-size: 24px;
      line-height: 22px;
      color: #000000;
      margin: 30px auto;
    }

    p {
      font-size: 16px;
      line-height: 25px;
      color: rgba(0, 0, 0, 0.6);
    }

    &.Campaigns__step-1 {
      background: #80CBF6;
    }

    &.Campaigns__step-2 {
      background: #97ABFA;
    }

    &.Campaigns__step-3 {
      background: #D4CBFF;
    }

    .btn {
      margin: 35px auto 0;
    }
  }
}

.dashboard_empty {
  .Campaigns__empty {
    .Campaigns__steps:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    }

    .Campaigns__steps {
      @media all and (min-width: 768px){
        min-height: 530px;
      }

      @media all and (min-width: 874px){
        min-height: 400px;
      }

      @media all and (min-width: 1050px){
        min-height: 360px;
      }

      @media all and (min-width: 1200px){
        min-height: 310px;
      }

      @media all and (min-width: 1600px){
        min-height: 300px;
      }
    }

    .mdi-icon {
      position: absolute;
      right: 30PX;
      top: 45px;
      z-index: 9;
      color: white;
    }

    .step_disabled {
      filter: blur(2px) grayscale(1);
      background-color: darkgray;
      color: grey;
    }
  }
}

#warm_intros .Campaigns__empty .Campaigns__steps {
  min-height: 469px;
}
