.container {
  margin: auto;

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1400px;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
  }
}

.container__wrap, .container__summary {
  padding-top: 123px;
  padding-right: 0;
  padding-left: 0;
  min-height: 100vh;
  transition: padding-left 0.3s;
  background: #ffffff;
}

.container__wrap:not(.container__summary) {
  @media screen and (min-width: 576px) {
    padding-left: 351px;
  }
}

.container__summary {
  .container {
    max-width: 1240px;
    margin: auto;
  }

  .topbar__wrapper {
    max-width: 1220px;
    margin: auto;
  }
}

.layout {

  &.layout--collapse {

    & + .container__wrap {
      padding-left: 30px;
      padding-right: 40px;

      @media screen and (min-width: 576px) {
        padding-left: 90px;
        padding-right: 20px;
      }

      @media screen and (min-width: 780px) {
        padding-left: 120px;
        padding-right: 50px;
      }
    }
  }

  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}
