.FixedPDFViewer {
  position: fixed!important;
  left: 0;
  right: 0;
  top: 60px;
}

.back-btn-fixed {
  position: fixed!important;
  z-index: 1000;
  top: 10px;
  left: calc(50% - 90px);
  width: 120px;
}

.notification-fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 60px;
  z-index: 100;
}

.PDFViewerWrapper {
  cursor: pointer;
  background: transparent;
  transition-duration: 1s;
  position: absolute;
  top: 0;
  width: 100%;
  height: 400px;
  text-align: center;
  padding-top: 190px;

  .text-overlay {
    visibility: hidden;
    color: white;
    transition-duration: 1s;
  }

  &:hover {
    background: rgba(39, 53, 73, 0.75);

    .text-overlay {
      visibility: visible;
    }
  }
}
