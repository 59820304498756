.modal {

  .modal-loader {
    position: absolute;
    background: rgba(255,255,255,0.9);
    width: 97%;
    height: 97%;
    z-index: 100;
    margin: 0;

    img {
      width: 100px;
      margin-top: 150px;
      margin-left: calc(50% - 50px);
    }
  }

  .modal-campaigns, .modal-find-intro {

    .modal__header {
      margin-left: 15px;

      .modal__investor-pic {
        width: auto;
        height: auto;
        max-width: 60px;
        max-height: 60px;
        float: left;
        border-radius: 40px;
        margin-right: 35px;
        margin-top: -10px;
      }
    }

    .campaign-select {
      max-width: 500px;
      margin: auto;
    }

    .select-boxes {
      margin: 55px 15px 10px;
      transition: 1s;

      > div:first-child {
        padding-left: 0;
      }
      > div:last-child {
        padding-right: 0;
      }

      .select-boxes-box {
        padding: 95px 40px 60px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        width: 100%;
        min-height: 367px;

        &#campaigns {
          background: linear-gradient(129.9deg, #59ACDE 12.01%, #97ABFA 72.74%, #A5B3FB 72.74%), #97ABFA;
        }

        &#email {
          border: 1px solid #828F96;

          h2 {
            color: #243143;
          }
        }

        h2 {
          font-weight: 700;
          font-size: 26px;
          line-height: 22px;
          text-align: center;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          margin-bottom: 30px;
        }

        .select-boxes-time {
          color: $color-additional !important;
          margin-top: 10px;
        }

        .select-boxes-title {
          color: white;
          font-size: 24px;
          font-weight: 500;
        }

        .select-boxes-subtitle {
          font-size: 16px;
          line-height: 25px;
          text-align: center;
          color: #243143;
          max-width: 350px;
          margin: auto;
        }

        button {
          margin: 35px auto 0;
        }

        &:hover {
          box-shadow: 0 1px 7px #89899b;
        }
      }

      &.one-box {
        height: 420px;
        width: 600px;
        position: absolute;
        left: calc(55% - 300px);
        top: calc(50% - 200px);
      }
    }


    hr {
      margin: 35px auto 20px;
    }
    .form {
      padding-bottom: 25px;

      h3 {
        width: 25%;
        min-width: 150px;
        display: inline-block;
        border-radius: 40px;
        padding: 10px 30px;
        font-size: 22px;
        background: #D1FFD0;
        margin-right: 5%;

        &.first-subject {
          background: #D8D0FF;
        }

        &.third-subject {
          background: #b7ecfc;
        }
      }

      .subject {
        display: inline-block;
        width: 70%;
        min-width: 200px;
      }

      .material-form__field {
        width: 100%;

        .MuiInput-input {
          border: none;
        }
      }
    }
    .submit-campaign {
      margin-left: auto;
      order: 2;
    }
    .height-tab {
      height: 400px;
      padding: 30px 30px 50px;
    }

    .tab-content {
      width: 100%
    }

    .tox-tinymce {
      margin-bottom: 60px
    }
  }

  .modal__list__investors {
    min-width: 300px;
    .list-btn-options {
      .btn {
        padding: 0.375rem 0.75rem;
      }
    }

    .paused-icon {
      position: absolute;
      top: 40px;
      left: 43px;
      width: 25px;
      height: 25px;
      background-color: white;
      border-radius: 50px;
      border: 1px solid black;

      svg {
        fill: black!important;
        position: absolute!important;
        bottom: 1px !important;
        right: 1px !important;
        height: 20px !important;
        width: 20px !important;
      }
    }
  }

  .modal-templates {
    .modal__template-alert {
      margin-top: 150px;
      text-align: left;

      .alert__content {
        padding: 40px 50px;
      }
    }
    .material-form {
      margin-top: 110px;
    }
    .modal-content {
      padding: 50px 30px 60px 40px;
    }
    .nav-tabs {
      width: 400px!important;
    }
    .modal__header {
      position: absolute;
      background: #97ABFA;
      color: white;
      left: 0;
      top: 0;
      height: 128px;
      padding: 57px 65px 10px;
      width: 100%;
      text-align: left;

      h4 {
        color: white;
        font-size: 20px;
        line-height: 22px;
      }

      .header__line {
        height: 3px;
        width: 80px;
      }
    }
    .modal__title:first-of-type {
      margin-top: 130px;
    }
  }

  #modal_multi_investors {
    .fund-picture {
      max-width: 60px;
      float: left;
      margin-right: 20px;
      border-radius: 30px;
      margin-top: -5px;
    }
  }
}
