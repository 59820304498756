.Links__visitors-empty {
  margin: 20px auto 30px;
  width: 100%;
  text-align: center;
}

.Links__visitor-icon {
  float: left;
  width: 35px;
  height: 35px;
  margin: 10px;
}

.Links__visitor-table th{
  @media all and (max-width: 992px) {
    font-size: 12px;
  }

  @media all and (max-width: 768px) {
    font-size: 11px;
  }

  @media all and (max-width: 576px) {
    font-size: 10px;
  }
}

.Links__visitor-table td,
.Links__visitor-table th{
  color: #646777!important;

  @media all and (max-width: 992px) {
    font-size: 12px;
  }

  @media all and (max-width: 768px) {
    font-size: 11px;
  }

  @media all and (max-width: 576px) {
    font-size: 10px;
  }
}

.Links__visitor-cell {

  svg {
    color: #38373f;
  }

  div {
    font-weight: 600;
    line-height: 16px;
    margin-top: 10px;
    color: #333;
  }

  span {
    font-size: 11px;

    &.Links__visitor-link {
      color: #686b7cc4!important;
    }
  }
}

.link-inactive {
  background-color: #f2f4f7;

  .link-name {
    color: #c2c4c6;
  }
}

.deck-head {
  line-height: 14px;

  small {
    color: #969696;
  }
}
