.material-form {

  .margin10 {
    margin: 10px auto;
  }

  .material-form__field {
    width: 100%;
    margin-bottom: 20px;

    & > div:before {

      @include themify($themes) {
        border-bottom-color: themed('colorFieldsBorder');
      }
    }

    & > div:hover:before {
      border-bottom: 1px solid $color-accent !important;
    }

    & > div:after {
      border-color: $color-accent;
    }

    input, textarea {
      font-size: 16px;

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    textarea {
      height: auto;
      min-height: 100px;
      width: 100%;
      border: none;
      color: #646777;
      margin-top: 15px;
      font-family: inherit;
      padding-left: 20px;
    }

    svg {
      @include themify($themes) {
        fill: themed('colorText');
      }
    }

    &.mw-150 {
      max-width: 150px;
    }
  }

  .material-form__label {
    margin: 0;
    font-size: 12px;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  label {
    font-size: 12px;

    @include themify($themes) {
      color: themed('colorText');
    }

    &[data-shrink="true"] {
      transform: translate(0, 1.5px) scale(0.85);
    }
  }
}

.material-form__option {
  font-size: 13px !important;
  height: 18px !important;
}

.material-form__form-group-field {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  margin: auto;
  height: 50px;
  margin-top: 10px;
}

.material-form__form-group-icon {
  border-bottom: 1px solid #f2f4f7;
  border-right: 1px solid #f2f4f7;
  padding: 6px;
  height: 32px;
}

.material-form__form-group-icon svg {
  fill: #999999;
  width: 18px;
  height: 18px;
  transition: all 0.3s;
}

.form__form-group-input-wrap {

  input {
    font-size: 16px!important;
    padding-left: 20px;
  }
}

.material-form textarea {
  height: auto;
  min-height: 100px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;
  color: #646777;
  margin-top: 15px;
  font-size: 16px;
  font-family: inherit;
  padding-left: 20px;
}

.summary-modal {

  .form__form-group-input-wrap {
    margin-bottom: 0px;
    margin-top: 10px;

    input {
      width: 100%;
      padding: 5px 10px;
      font-size: 13px;
      height: 45px;
      -webkit-transition: border 0.3s;
      transition: border 0.3s;
      background: transparent;
      border: 1px solid #f2f4f7;
      color: #646777;

      &:focus {
        outline: none;
        border-color: #4ce1b6;
      }
    }
  }

  .text-danger {
    margin-top: 20px;
    margin-bottom: -40px;
  }
}
