.profile {
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 230px;
    background-color: #97ABFA;

    &.bg-alt {
      background-color: #67BAEB;
    }
  }

  .profile__video {
    margin-bottom: 30px;
  }

  .profile__card {
    padding: 0;

    h4 {
      font-weight: 600;
      line-height: 22px;
      border-bottom: 1px solid #D3D6DB;
      padding-bottom: 20px;
      font-size: 16px;
      margin-bottom: 20px;
    }

    .text-muted {
      line-height: 25px;
    }

    .row {
      margin: 10px;
    }

    .card {
      margin-bottom: 10px;
    }

    .table-shadowed {
      box-shadow: 0 4px 40px rgba(0, 0, 0, 0.12);
      padding: 5px 15px;
      z-index: 10;
    }

    .table {
      tr:first-child td {
        border-top: 0;
      }

      td {
        padding: 20px 0;
      }

      td:first-child {
        color: $color-additional;

        @media all and (min-width: 1140px) {
          min-width: 210px;
        }
      }

      td:last-child {
        color: $color-new-gray;

        @media all and (min-width: 1140px) {
          min-width: 200px;
        }

        @media all and (max-width: 1139px) {
          padding-left: 30px;
        }
      }
    }

    .timeline .timeline__item:nth-child(even) {

      .timeline__content {

        &:after {

          @include themify($themes) {
            border-right-color: themed('colorBackgroundBody');
          }
        }
      }
    }

    &--calendar {
      .rbc-toolbar {
        -webkit-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important;
      }
    }

    .profile__main {
      padding: 10px 0px 10px;
      line-height: 30px;
      font-size: 14px;
      color: #686b7c;
      display: block;

      h4 {
        line-height: 40px;
      }
    }

    .profile__main.first-row {
      margin-top: 30px;
    }

    @media screen and (min-width: 574px) and (max-width: 1091px) {
      .profile__main.second-row {
        margin-top: 30px;
      }
    }

    @media screen and (min-width: 761px) and (max-width: 1091px) {
      .profile__main.third-row {
        margin-top: 30px;
      }
    }

    .profile__main.last-row {
      border-top: none;
    }

    .pic-deck {
      width: auto;
      height: 100px;
      display: block;
      margin: 30px auto 20px auto;
    }

    .text-deck {
      height: 30px;
      line-height: 30px;
    }

    .text-deck .text-muted{
      font-size: 13px;
    }

    .extinvestor__investments {
      svg {
        fill: #67BAEB;
        width: 22px;
        float: right;
      }

      .extinvestor__investments-cell-company {
        svg {
          fill: black;
          width: 20px;
          float: none;
        }
      }
    }

    .extinvestor__open-desc {
      background-color: $color-new-gray;
      color: white;
      padding: 50px;
      text-align: justify;

      h2 {
        color: white;
        font-weight: 600;
        font-size: 20px;
        line-height: 31px;
      }

      h5 {
        color: white;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        color: #CACACA;
      }
    }
  }

  .profile__information {

    @media screen and (min-width: 576px) {
      padding: 30px 40px;
    }

    @media screen and (max-width: 360px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    .profile__avatar {
      height: auto;
      max-width: 280px;
      padding: 15px;
      //overflow: hidden;
      white-space: nowrap;
      text-align: center;
      margin: auto;
      background-color: white;
      box-shadow: 0 4px 30px rgba(39, 53, 73, 0.2);
      margin-bottom: 20px;

      img {
        width: 100%;
        height: auto;
        margin: auto;
      }

      .helper {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }
    }

    .profile__links {
      width: 100%;
      text-align: center;

      img {
        width: 30px;
        margin-top: 20px;
        margin-right: 10px;
      }

      a:last-child > img {
        margin-right: 0;
      }
    }

    .profile__data {
      width: 100%;
      display: block;

      @media screen and (max-width: 360px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0;
      }
    }

    .row {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }

    .btn {
      display: inline-block;
      margin: 25px 20px 20px 0;

      @media all and (max-width: 1200px) {
        margin: 25px 10px 0 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .profile__contact-info {
    display: none;
    visibility: hidden;
    //display: block;
    padding: 30px 40px;
  }

  .profile__investor img{
    border-radius: 10px;
  }

  .profile__stats {
    display: flex;
    justify-content: space-around;
  }

  .profile__stat {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 15px;
  }

  .profile__stat-number {
    color: $color-blue;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }

  .profile__stat-title {
    margin: 0;
    color: $color-additional;
    font-size: 12px;
    line-height: 14px;
  }

  .profile__name {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 30px;
    line-height: 22px;
  }

  .profile__work {
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 15px;
    opacity: 0.6;
    font-size: 16px;
    line-height: 25px;
  }

  .profile__location {
    margin-top: 6px;
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 22px;

    svg {
      margin-right: 10px;
      font-size: 18px;
      line-height: 40px;
      color: black;
    }
  }

  .extinvestor__report {
    color: #B2456C;
    cursor: pointer;
  }

  .profile__btn {
    margin-top: 10px;
    margin-bottom: 0;
    padding: 8px 15px;
  }

  .profile__activity {
    display: flex;
    border-bottom: solid 1px #e7e7e7;
    padding: 20px 0;

    &:last-child {
      border: none;
    }

    img {
      display: inline-block;
      vertical-align: top;
      margin-top: 10px;
      width: auto;

      @media screen and (max-width: 576px) {
        width: 100%;
      }
    }
  }

  .profile__activity-avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    min-width: 64px;

    img {
      height: 64px;
      width: 64px;
      margin: 0;
    }
  }

  .profile__activity-name {
    font-weight: 700;
    color: $color-blue;
  }

  .profile__activity-date {
    font-weight: 400;
    color: $color-additional;
    font-size: 10px;
  }

  .profile__current-tasks-title {
    padding: 20px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    margin: 0;

    @include themify($themes) {
      border-bottom: 1px solid themed('colorBorder');
    }

    span {
      color: $color-additional;
      font-size: 13px;
    }
  }

  .profile__current-tasks {
    position: relative;
    padding-bottom: 40px;
  }

  .profile__current-task {
    display: flex;
    margin: 0 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;

    @include themify($themes) {
      border-bottom: 1px solid themed('colorBorder');
    }

    &:last-of-type {
      border-bottom: none;
    }

    .badge {
      text-transform: uppercase;
      font-size: 9px;
      padding: 6px 10px;
      display: inline-table;
      height: 20px;
      color: #ffffff;

      &.badge-info {
        background-color: $color-accent;
      }

      &.badge-error {
        background-color: $color-red;
      }
    }
  }

  .profile__current-task-checkbox {
    position: absolute;
  }

  .profile__see-all-tasks {
    position: absolute;
    bottom: 20px;
  }

  .profile__account tbody tr td:first-child {
    padding-left: 40px;
  }

  .profile__account tbody tr td:last-child {
    padding-right: 20px;
  }
}

.profile__review-block {
  width: 100%;
  @media screen and (min-width: 960px) and (max-width: 1200px) {
    width: auto;
  }
}
