.dashboard-card {
  text-align: center;
  cursor: pointer;
  border: 1px solid #d9d9f3;
  border-radius: 20px;
  box-shadow: 0 0 5px #b1b1c8;

  &.dashboard-card--primary {
    border-top: 5px solid $color-blue;
  }

  &.dashboard-card--info {
    border-top: 5px solid $color-accent;
  }

  &.dashboard-card--info-horizontal {
    border-left: 5px solid $color-accent;
  }

  &.dashboard-card--warning {
    border-top: 5px solid $color-yellow;
  }

  &.dashboard-card--danger {
    border-top: 5px solid $color-red;
  }

  hr {
    margin: 0;

    @include themify($themes) {
      border-top-color: themed('colorFieldsBorder');
    }
  }

  &:hover {
    box-shadow: 0 1px 7px #89899b;
  }
}

.btn-import-investor {
  margin-top: -10px;
}

@media all and (max-width: 991px) {
  .btn-import-investor {
    margin-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.dashboard-card__body {
  padding: 45px 37px;

  .dashboard-card__img {
    margin-bottom: 20px;

    &.round {
      height: 90px;
    }
  }

  .dashboard-card__img-partnerships {
    width: auto;
    height: 180px;
  }

  &.dashboard-card__body-horizontal {
    text-align: left;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 134px;

    .dashboard-card__title {
      margin-top: 10px;
    }

    .dashboard-card__button {
      float: right;
      margin: 0;
    }
  }

  .dashboard-card__button {
    margin: 25px auto auto;
    margin-right: auto!important;
    max-width: 250px;
    width: 100%;
  }
}

.dashboard-card__img-horizontal {
  height: 80px;
  margin: 98px 50px 60px 31px;
  float: left;
  width: 80px;
}

.dashboard-card__plan {
  margin-bottom: 20px;
  font-weight: 300;
}

.dashboard-card__title {
  margin-top: 30px;
  font-weight: 300;
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 30px;
}

.dashboard-card__desc {
  margin-top: 10px;
  &.top-line {
    min-height: 171px;
  }

  &.second-line {
    min-height: 225px;

    &.shorter{
      min-height: 193px;
    }
  }
}

.dashboard-card__desc-horizontal {
  margin-top: 10px;
}

.dashboard-card__blockquote {
  margin-top: 20px;
  font-weight: 300;
  font-size: 22px;
  line-height: 40px;
  font-style: italic;
  color: #9396a5!important;
}

.dashboard-input-new {
  text-align: center;
  padding-bottom: 30px;
}

.dashboard-sidebar {
  background-color: $color-blue;
  min-height: 667px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;

  p {
    text-align: justify;
    color: white;
  }

  li, a {
    text-align: left;
    color: white;
    line-height: 24px;

    &:hover {
      font-weight: bold;
    }
  }

  .dashboard-sidebar-image {
    width: 130px;
    margin-bottom: 20px;
  }

  .dashboard-date-filter,
  .dashboard-priority-filter {
    align-self: flex-start;
    margin-bottom: 20px;

    .title {
      font-size: 12px;
      margin-bottom: 15px;
      color: white;
      opacity: 0.6;
    }
  }

  .dashboard-date-filter-list,
  .dashboard-priority-filter-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li:not(:last-child) {
      margin-bottom: 10px;
    }

    label {
      margin: 0;
      color: white;
      font-size: 12px;
    }
  }
}

.dashboard-btn {
  color: white!important;
  padding: 5px 10px;
  min-width: 130px;
  background-color: #70bbfd!important;
  margin: 0;
  border: 2px solid white;
  border-radius: 100px;
  margin-bottom: 20px;

  &:before {
    background-color: lighten($color-blue, 10%)!important;
  }

  &:hover, &:active, &:focus {
    color: white!important;
  }

  svg, img {
    height: 16px;
    width: 16px;
    margin: 0;
    fill: white;
  }
}

.margin-20 {
  margin: 20px;
}

.margin-15 {
  margin: 0 15px!important;
}

.margin-0 {
  margin: 0!important;
}

.dashboard-steps,
.dashboard-warnings {
  transition: 1s;

  &:not(.center-block) {
    margin: 55px 0;
  }

  &.elements-cards {
    margin: 0 auto 10px;

    .dashboard__step-slide {
      opacity: 1!important;
      cursor: auto!important;
      padding: 40px 28px 40px 35px;

      .dashboard__step-subtitle {
        color: $color-bg-primary;
      }

      .line {
        width: 80px;
        height: 3px;
        margin: 17px auto 17px 0;
      }
    }
  }

  @media all and (max-width: 575px) {
    .crm-steps {
      margin: 10px auto;
    }
  }

  .dashboard__step-slide {
    padding: 35px 50px;
    position: relative;
    overflow: hidden;

    &.rounded {
      border-radius: 20px!important;
      position: relative;
      overflow: hidden;
      height: 200px;
    }

    .dashboard__step-title {
      color: white;
      font-size: 40px;
      font-weight: 700;
    }

    .dashboard__step-subtitle {
      color: white;
      font-size: 22px;
      font-weight: 300;
      line-height: 25px;
    }

    .crm-steps-icon {
      position: absolute;
      right: 40px;
      height: 50px;
      width: 80px;

      &#sent_icon{
        height: 60px;
        width: 90px;
        top: 30px;
      }

      &#mail_icon{
        height: 40px;
        width: 70px;
      }
    }

    p, ul {
      color: white;
      margin: 0;

      strong {
        color: $color-gray;
      }
    }

    @include themify($themes) {
      background: themed('colorBackgroundBody');
    }

    &.default-cursor {
      cursor: default;
    }

    &.dashboard__step-slide--next {
      background: $color-purple;

      svg {
        fill: #ffffff;
      }
    }

    &.dashboard__step-slide--warning {
      background-color: lighten($color-yellow, 20%);
      border: 0 solid $color-yellow-hover;
      border-left-width: 5px;

      h3, h4, ul, li {
        color: #856404;
      }

      svg {
        fill: $color-yellow;
      }
    }

    &.dashboard__step-slide--current {
      background: $color-violet;
      cursor: pointer;
      svg {
        fill: #ffffff;
      }
    }

    &.dashboard__step-slide--success {
      background: $color-blue;
      svg {
        fill: #ffffff;
      }
    }
  }

  .dashboard__step-summary {
    padding: 30px;

    .dashboard__step-go {
      margin-top: 0;
      margin-bottom: -20px;
    }
  }
}

.Campaigns__empty {
  .steps {
    @media all and (min-width: 768px) {
      min-height: 406px;
    }

    @media all and (min-width: 874px) {
      min-height: 500px;
    }

    @media all and (min-width: 1050px) {
      min-height: 415px;
    }

    @media all and (min-width: 1200px) {
      min-height: 537px;
    }

    @media all and (min-width: 1600px) {
      min-height: 395px;
    }
  }
}

.Campaigns__empty, .Plugin__install, .Broker__empty, .Dashboard__empty {
  .steps {
    @media all and (min-width: 768px){
      min-height: 550px;
    }

    @media all and (min-width: 874px){
      min-height: 500px;
    }

    @media all and (min-width: 1050px){
      min-height: 400px;
    }

    @media all and (min-width: 1200px){
      min-height: 350px;
    }

    @media all and (min-width: 1600px){
      min-height: 330px;
    }
  }
}

.Campaigns__empty, .Plugin__install, .Broker__empty, .Dashboard__empty {
  .steps {
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.12);
    padding: 55px 55px 50px;
    margin: 30px 0;

    h2 {
      font-weight: 700;
      font-size: 45px;
      line-height: 22px;
      color: #FFFFFF;
      margin-bottom: 20px;
    }

    h3 {
      font-weight: 500;
      font-size: 24px;
      line-height: 22px;
      color: #000000;
      margin: 30px auto;
    }

    p {
      font-size: 16px;
      line-height: 25px;
      color: rgba(0, 0, 0, 0.6);
    }

    &.step-1 {
      background: #80CBF6;
    }

    &.step-2 {
      background: #97ABFA;
    }

    &.step-3 {
      background: #D4CBFF;
    }

    .btn {
      margin: 35px auto 0;
    }

    .line {
      width: 120px;
      height: 5px;
      margin: 3px auto 5px 0;
    }

    .mdi-icon {
      position: absolute;
      right: 50px;
      top: 65px;
      color: white;
    }
  }

  .steps:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  }

  .step_disabled {
    filter: blur(2px) grayscale(1);
    background-color: darkgray;
    color: grey;
  }
}
