.collapse__wrapper {
    text-align: left;

  &.opened, &.opening {

    .collapse__title {

      p {
        color: $color-additional;
      }
    }
  }

  &.boxed {
    border: solid 1px #dddddd;
    margin-top: -1px;

    .collapse__title {
      background-color: #f2f4f7;
      border-bottom: solid 1px #dddddd;
      padding: 8px 15px;

      p {
        color: #444444;
      }

      svg {
        display: none;
      }
    }

    .collapse__content {
      // padding-right: 20px;
      // padding-left: 15px;
      // padding-top: 16px;
      // padding-bottom: 20px;
    }

    &.closed {

      .collapse__title {
        border-bottom: none;
      }
    }
  }

  &.links-collapse {
    margin-bottom: 10px;
    box-shadow: none;

    .collapse__content {
      // padding-right: 20px;
      // padding-left: 25px;
      // padding-top: 15px;
      // padding-bottom: 0px;

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    .collapse__title {
      padding: 12px 25px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
      transition: background-color 0.3s;

      @include themify($themes) {
        color: themed('colorText');
      }

      p {

        svg {
          display: block;
          top: calc(50% - 7px);
          transition: all 0.3s;

          @include themify($themes) {
            fill: themed('colorText');
          }
        }
      }
    }

    &.opened .collapse__title,
    &.opening .collapse__title {

      p {
        color: inherit;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }

  &.with_border {
    margin-bottom: 10px;
    box-shadow: none;

    .collapse__content p {
      font-size: 16px;
      line-height: 25px;
      color: #6F7C84;
      margin: 20px 0;
    }

    .collapse__title {
      padding: 20px 25px;
      border-bottom: 1px solid #D5D3D3;
      transition: background-color 0.3s;

      @include themify($themes) {
        color: themed('colorText');
      }

      svg {
        display: block;
        top: calc(50% - 7px);
        transition: all 0.3s;
        right: 12px;
        position: absolute;

        @include themify($themes) {
          fill: themed('colorText');
        }
      }

      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        margin-top: 2px;
      }
    }

    &.opened .collapse__title,
    &.opening .collapse__title {

      p {
        color: inherit;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }

  &.closing {

    .collapse__content {
      padding-top: 0;
      padding-bottom: 0;
      transition: all 0.3s;
    }
  }
}

.collapse__title:hover .collapse__icon {
  @include themify($themes) {
    background-color: themed('colorHover');
  }
}

.collapse__title, .collapse__content {
    padding-left: 20px;
    padding-right: 15px;
}

.collapse__title {
  padding-bottom: 10px;
  padding-top: 6px;
  position: relative;
  background: transparent;
  display: flex;
  width: 100%;
  border: none;
  text-align: left;
  margin-right: 0;
  margin-left: 0;

  .link {
    text-overflow: ellipsis;
    min-width: 200px;

    span {
      display: contents;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      float: left;
      margin-top: 6px;
    }

    .link__address {
      max-width: 100%;
      width: 100%;
      font-size: 12px;
      height: 36px;
      line-height: 35px;
      background-color: #f4f4f4;
      color: #666;
      border-radius: 18px;
      overflow: hidden;
      border: 1px solid #fafafa;
      padding: 0px 15px;
    }

    .copy-to-clipboard {
      align-items: center;
      opacity: 0;
      z-index: 10;
      background-color: #fff;
      border: 1px solid #6d31ff;
      border-radius: 24px;
      color: #6d31ff;
      text-align: center;
      font-weight: 600;
      position: absolute;
      top: 0;
      width: 96%;
      height: 36px;
      margin-bottom: -33px;
      cursor: pointer;

      &:hover {
        opacity:1;
      }
    }
  }

  .link-buttons {
    @media all and (max-width: 1500px) {
      .btn.small {
        padding: 8px 20px;
      }
    }
    @media all and (max-width: 1200px) {
      .btn.small {
        padding: 8px 15px;
      }
    }
    @media all and (max-width: 992px) {
      .btn.small {
        padding: 5px 12px;
      }
    }
    @media all and (max-width: 900px) {
      .btn.small {
        padding: 5px 10px;
      }
    }
    @media all and (max-width: 576px) {
      .btn.small {
        padding: 5px;
      }
    }
  }

  & > div {
    font-weight: 700;
    margin-top: 0;
    transition: all 0.3s;
    position: relative;
    line-height: 38px;
  }

  .btn {
    margin-bottom: 0 !important;
    svg {
      margin: 3px 0 0 0;
    }
  }
}

.text-visits {
  padding-right: 50px;
  text-align: center;
  color: #38373f;
  font-weight: 500;
  font-size: 11px;
}


// Special Styling for collapse inside a notification

.notification--collapse {
  .collapse__wrapper {
    text-align: left;

    &.opened, &.opening {

      .collapse__title {

        p {
          color: white;
        }
      }
    }

    .collapse__title {
      color: white;

      p {
        font-size: 14px;
        font-weight: 700;
        margin-top: 1px;
      }

      svg {
        margin-right: 8px;
      }
    }
    p {
      font-weight: 500;
      color: white;
    }
  }
}
